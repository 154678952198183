import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { formatDate } from '../../../utils/common.service';
import { preferred_teachers } from '../../../api/apiHandler';
import { Accordion, AccordionItem, AccordionButton, AccordionBody } from 'react-bootstrap';
import moment from 'moment';

export default function PrefferedTeachers({ CategoriesData, subCategoriesData, startDate, endDate, classSize, isLoader, search }) {
    var [data, setData] = useState([]);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const PreferTeachers = () => {

        var search_item = {};
        if (CategoriesData && subCategoriesData?.[0]) {
            search_item.category_list = CategoriesData
            search_item.sub_category = subCategoriesData
        }
        if (startDate) {
            search_item.start_date = formatDate(startDate, 'DD-MM-YYYY')
        }
        if (endDate) {
            search_item.end_date = formatDate(endDate, 'DD-MM-YYYY')
        }
        if (classSize) {
            search_item.class_size = classSize
        }
        if (search) {
            search_item.search = search
        }
        search_item.page = currentPage;
        isLoader(true)
        preferred_teachers(search_item).then((response) => {
        console.log('response :', response);
            if (response?.code == 1) {
                setData(response?.data?.result)
                isLoader(false)
                setTotalPages(response?.data?.totalPages);
            } else {
                setData([])
                isLoader(false)
            }
            isLoader(false)
        });
    }

    useEffect(() => {
        PreferTeachers();
    }, [CategoriesData, subCategoriesData, startDate, endDate, classSize, search, currentPage]);

    const handlePaginationClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    return (
        <div className="row">
            <div className="col-xl-9">
                <div className="lead_request">
                    {data?.length > 0 ? (
                        data.map((leads_data, index) => (
                            <div className="card mb-4" key={index}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center mb-3">
                                        <img className="rounded-circle" src={leads_data?.profile_image} alt="user" style={{ width: 60, height: 60 }} />
                                        <div className="ms-3">
                                            <span className="fw-600">{leads_data.teacher_name}</span>
                                        </div>
                                    </div>
                                    <ul className="list-unstyled">
                                        <li className="mb-2">
                                            <span className="fw-500" style={{ color: '#570861' }}>Teacher's Country: </span>
                                            <span className="text-dark">{leads_data?.teacher_country_name || 'N/A'}</span>
                                        </li>
                                        <li className="mb-2">
                                            <span className="fw-500" style={{ color: '#570861' }}>Teacher’s Local Time: </span>
                                            <span className="text-dark">{leads_data.local_time || 'N/A'}</span>
                                        </li>
                                        <li className="mb-2">
                                            <span className="fw-500" style={{ color: '#570861' }}>Subscription Plan: </span>
                                            <span className="text-dark">{leads_data?.subscription__details[0]?.type || 'N/A'}</span>
                                        </li>
                                        <li className="mb-2">
                                            <span className="fw-500" style={{ color: '#570861' }}>Subscription Status: </span>
                                            <span className="text-dark">{leads_data?.subscription__details[0]?.status || 'N/A'}</span>
                                        </li>
                                        <Accordion>
                                            {leads_data.sponsoed_teacher_details?.length > 0 ? (
                                                leads_data.sponsoed_teacher_details.map((details, idx) => (
                                                    <AccordionItem eventKey={idx.toString()} key={idx}>
                                                        <AccordionButton>
                                                            <p className="fw-500" style={{ color: '#570861' }}>Category {idx + 1}</p>
                                                        </AccordionButton>
                                                        <AccordionBody>
                                                            <div>
                                                                <p className="fw-500" style={{ color: '#570861' }}>Teaching Categories:</p>
                                                                <div className="row">
                                                                    {details.category_list ? (
                                                                        <span className='mb-2 mt-1'>{details.category_list}</span>
                                                                    ) : (
                                                                        <div className="mb-2">
                                                                            <span className="text-dark mb-2">No categories available</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <p className="fw-500" style={{ color: '#570861' }}>Teaching Subjects:</p>
                                                                <div className="row">
                                                                    {details.subcategory_list ? (
                                                                        <span className='mb-2 mt-1'>{details.subcategory_list}</span>
                                                                    ) : (
                                                                        <div className="mb-2">
                                                                            <span className="text-dark">No subjects available</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <p className="fw-500" style={{ color: '#570861' }}>Class Size:</p>
                                                                <div className="row">
                                                                    <div className="mb-2">
                                                                        <span className="text-dark mb-2 mt-1">
                                                                            {details.class_size === "Individual/private" ? "1-on-1 Private Lesson" : "Group Lesson" || 'No class size data'}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <p className="fw-500" style={{ color: '#570861' }}>Availability:</p>
                                                                <div className="row">
                                                                    <div className="mb-2">
                                                                        <span className="text-dark mb-2 mt-1">{details.availability || 'No availability data'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </AccordionBody>
                                                    </AccordionItem>
                                                ))
                                            ) : (
                                                <div>
                                                    <p className="fw-500" style={{ color: '#570861' }}>Teaching Categories:</p>
                                                    <p className="text-dark">No categories available</p>
                                                    <p className="fw-500" style={{ color: '#570861' }}>Teaching Subjects:</p>
                                                    <p className="text-dark">No subjects available</p>
                                                    <p className="fw-500" style={{ color: '#570861' }}>Class Size:</p>
                                                    <p className="text-dark">No class size data</p>
                                                    <p className="fw-500" style={{ color: '#570861' }}>Availability:</p>
                                                    <p className="text-dark">No availability data</p>
                                                </div>
                                            )}
                                            <li className="mb-2 mt-2">
                                                <span className="fw-500" style={{ color: '#570861' }}>Date of became a preferred teacher: </span>
                                                <span className="text-dark">
                                                    {leads_data?.date_bacame_Preferred_teacher
                                                        ? moment(leads_data.date_bacame_Preferred_teacher).format('DD-MM-YYYY')
                                                        : 'N/A'}
                                                </span>
                                            </li>
                                            <li className="mb-2">
                                                <span className="fw-500" style={{ color: '#570861' }}>
                                                    Date of stopped the preferred teacher subscription :
                                                </span>
                                                <span className="text-dark">
                                                    {leads_data?.date_stopped_Preferred_teacher_subscription && leads_data.date_stopped_Preferred_teacher_subscription !== "0000-00-00 00:00:00"
                                                        ? moment(leads_data.date_stopped_Preferred_teacher_subscription).format('DD-MM-YYYY')
                                                        : 'N/A'}
                                                </span>
                                            </li>

                                            <li>
                                                <span className="fw-500 text-decoration-underline" style={{ color: '#570861', cursor: "pointer" }} onClick={() => navigate("/new_leads_class_details", { state: { teacher_id: leads_data.user_id } })}>
                                                    Number of times learners actually registered to their class: <span className="text-dark">{leads_data?.actually_subscribed || 'N/A'}</span>
                                                </span>
                                            </li>
                                        </Accordion>
                                    </ul>
                                </div>
                            </div>
                        ))
                    ) : (
                        <h4 className="text-center mt-5" style={{ color: "#570861" }}>Data Not Found</h4>
                    )}

                    <div className="custom_pagenation mb-5 mt-1">
                        <ul className="d-flex align-items-center justify-content-center">
                            <li className={`circle ${currentPage === 1 ? 'disabled' : ''}`} onClick={handlePreviousPage}>
                                <Link className="text-purple font-w" onClick={handlePreviousPage}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.354 12.354a.5.5 0 0 1-.708 0L5.5 7.707l5.146-5.147a.5.5 0 0 1 .708.708L6.707 7.5l4.647 4.646a.5.5 0 0 1 0 .708z" />
                                    </svg>
                                </Link>
                            </li>
                            {[...Array(totalPages).keys()].map((pageNumber) => (
                                <li key={pageNumber} className={`circle ${pageNumber + 1 === currentPage ? 'active' : ''}`} onClick={() => handlePaginationClick(pageNumber + 1)}>
                                    <Link className="text-purple font-w" onClick={() => handlePaginationClick(pageNumber + 1)}>
                                        {pageNumber + 1}
                                    </Link>
                                </li>
                            ))}
                            <li className={`circle ${currentPage === totalPages ? 'disabled' : ''}`} onClick={handleNextPage}>
                                <Link className="text-purple font-w" onClick={handleNextPage}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M4.646 3.646a.5.5 0 0 1 .708 0L10.5 9.293l-5.146 5.147a.5.5 0 0 1-.708-.708L9.293 9.5 4.646 4.854a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}