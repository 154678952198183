import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Hdashboard from '../../../components/Hdashboard';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import { uploadImageOnAWS } from '../../aws/Service';
import { addNewsLetter } from '../../../api/apiHandler';
import { Button, Modal } from "react-bootstrap";

const Editor = ({ content, onChange }) => {
    return (
        <ReactQuill
            value={content}
            onChange={onChange}
            modules={Editor.modules}
            formats={Editor.formats}
            theme="snow"
            placeholder="Write something..."
            style={{ borderRadius: '10px' }}
        />
    );
};

Editor.modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean'],
    ],
};

Editor.formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
    'image',
];

const getVideoThumbnail = (file) => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);
        video.load();

        // Wait for the video metadata to load
        video.addEventListener('loadeddata', () => {
            // Set the current time to a point in the video (e.g., 5 seconds)
            video.currentTime = 5;
        });

        video.addEventListener('seeked', () => {
            // Create a canvas element to capture the video frame
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            const context = canvas.getContext('2d');
            if (context) {
                context.drawImage(video, 0, 0, canvas.width, canvas.height);
                const dataUrl = canvas.toDataURL('image/png');
                resolve(dataUrl);
            } else {
                reject(new Error('Failed to create canvas context.'));
            }

            // Clean up the object URL
            URL.revokeObjectURL(video.src);
        });

        video.addEventListener('error', (e) => {
            console.error('Video error:', e);
            reject(e);
        });
    });
};

export default function AddNewsLetter({ isLoader }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();
    const location = useLocation();
    const [sendNow, setSendNow] = useState(true);
    const [scheduleDate, setScheduleDate] = useState('');
    const [scheduleTime, setScheduleTime] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [AttachmentData, setAttachmentData] = useState({});
    const [previewData, setPreviewData] = useState(null);
    const [profileImageState, setProfileImageState] = useState('');
    const [profileImageState1, setProfileImageState1] = useState('');
    const [showName, setShowName] = useState({});

    var schema = yup.object().shape({
        newsletter_title: yup.string().required('Newsletter title is required'),
        newsletterdata: yup.array().of(
            yup.object().shape({
                description: yup.string().required('Description is required'),
                url: yup.string().url('Invalid URL'),
                attachments: yup.array().of(
                    yup.object().shape({
                        attachments: yup.string().required('Attachment URL is required'),
                        attachment_type: yup.string().required('Attachment type is required'),
                        thumb_image: yup.string().nullable() // Make sure this is nullable if not always required
                    })
                ).min(1, 'At least one attachment is required').nullable()
            })
        ).required('At least one description is required')
    });
    if (!sendNow) {
        schema = schema.shape({
            schedule_date: yup.date()
                .transform((value, originalValue) => originalValue === '' ? null : value)
                .required('Schedule date is required')
                .min(new Date(), 'Date must be in the future'),
            schedule_time: yup.string()
                .transform((value, originalValue) => originalValue === '' ? null : value)
                .required('Schedule time is required')
        });
    } else {
        schema = schema.shape({
            schedule_date: yup.date()
                .nullable()
                .transform((value, originalValue) => originalValue === '' ? null : value),
            schedule_time: yup.string()
                .nullable()
                .transform((value, originalValue) => originalValue === '' ? null : value)
        });
    }

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            newsletterdata: [{ description: '', url: '', attachments: [] }]
        }
    });

    console.log('errors :', errors);

    const handleImageChange = async (image, Attachment_Index, fileIndex, type) => {
        setShowName(image.name);

        if (image) {
            TOAST_SUCCESS('Your attachment has been uploaded!');
        }

        let str = 'attachment_' + Attachment_Index;
        let temp_arr = { ...AttachmentData }; // Create a copy of the state
        console.log('AttachmentData :', AttachmentData);

        try {
            if (type === 'video') {
                const thumbnailDataUrl = await getVideoThumbnail(image);
                const thumbnailBlob = await fetch(thumbnailDataUrl).then(res => res.blob());
                const thumbnailFile = new File([thumbnailBlob], 'thumbnail.png', { type: 'image/png' });

                let video_name = await uploadImageOnAWS(image, 'newsletter_attachments');
                let thumbnail_name = await uploadImageOnAWS(thumbnailFile, 'newsletter_thumbnails');

                if (!temp_arr[str]) {
                    temp_arr[str] = [];
                }
                temp_arr[str][fileIndex] = { attachments: video_name, attachment_type: type, thumb_image: thumbnail_name };
            } else {
                let image_name = await uploadImageOnAWS(image, 'newsletter_attachments');
                if (!temp_arr[str]) {
                    temp_arr[str] = [];
                }
                temp_arr[str][fileIndex] = { attachments: image_name, attachment_type: type };
            }

            // Update the AttachmentData state without affecting description validation
            setAttachmentData(temp_arr);
            setValue('newsletterdata', newsletterDetails.map((item, index) => ({
                ...item,
                attachments: temp_arr[`attachment_${index}`] || item.attachments,
            })));

            // Ensure description errors are not cleared
            if (errors.newsletterdata) {
                clearErrors('newsletterdata');
            }

        } catch (error) {
            console.error('Error handling attachment:', error);
        }

        // Set profile image states if needed
        const bufferImage = await convertToBase64(image);
        if (fileIndex === 0) {
            setProfileImageState(bufferImage);
        } else {
            setProfileImageState1(bufferImage);
        }
        clearErrors('attachments_data');
    };

    const handleImageChange1 = async (image) => {
        if (image != null) {
            TOAST_SUCCESS('Your attachment has been uploaded!');
        }
        setValue('attachments_data1', image);
        const bufferImage = await convertToBase64(image);
        setProfileImageState1(bufferImage);
        clearErrors('attachments_data1');
    };

    const onSubmit = async (data) => {
        console.log('data :', data);

        let coupon_data = {
            title: data.newsletter_title,
            send_time: sendNow ? "now" : "schedule",
            ...(sendNow === false && {
                schedule_date: data.schedule_date,
                schedule_time: data.schedule_time
            })
        };

        let description_arr = newsletterDetails.map((lesson, index) => {
            let temp_obj = {};
            temp_obj.description = lesson.description;
            temp_obj.link = lesson.url;

            let attach_str = 'attachment_' + index;
            if (AttachmentData[attach_str]) {
                let originalArray = AttachmentData[attach_str];
                let newArray = originalArray.filter(item => item !== '');
                temp_obj.attachments = newArray.map(item => ({
                    attachments: item.attachments,
                    attachment_type: item.attachment_type,
                    thumb_image: item.thumb_image
                }));
            } else {
                temp_obj.attachments = [];
            }
            return temp_obj;
        });

        coupon_data.newsletterdata = description_arr;
        isLoader(true)
        addNewsLetter(coupon_data).then((res) => {
            console.log('res :', res);
            if (res?.code == 1) {
                TOAST_SUCCESS(res.message);
                navigate('/news_letter');
                isLoader(false)
            } else {
                TOAST_ERROR(res.message);
                isLoader(false)
            }
        });
        reset();
    };

    const [newsletterDetails, setnewsletterDetails] = useState([{ description: '', attachment: null, url: '' }]);

    const handleDescriptionChange = (index, value) => {
        const updatednewsletterDetails = [...newsletterDetails];
        updatednewsletterDetails[index].description = value;
        setnewsletterDetails(updatednewsletterDetails);
    };

    const handleUrlInputChange = (index, value) => {
        const updatednewsletterDetails = [...newsletterDetails];
        updatednewsletterDetails[index].url = value;
        setnewsletterDetails(updatednewsletterDetails);
    };

    const handleAttachmentChange = (index, file) => {
        const updatednewsletterDetails = [...newsletterDetails];
        updatednewsletterDetails[index].attachment = file;
        setnewsletterDetails(updatednewsletterDetails);
    };

    const handleAddDescription = () => {
        setnewsletterDetails([...newsletterDetails, { description: '', attachment: null }]);
    };

    const preparePreviewData = () => {
        const formData = getValues();
        const thumbBaseUrl = 'https://hlink-bhavinp-s3.s3.amazonaws.com/student_scholar/newsletter_thumbnails/';
        const attachmentBaseUrl = 'https://hlink-bhavinp-s3.s3.amazonaws.com/student_scholar/newsletter_attachments/';

        return {
            title: formData.newsletter_title,
            description: formData.newsletterdata.map((item, index) => {
                const attachments = AttachmentData[`attachment_${index}`] || [];
                return {
                    id: index + 1,
                    description: item.description,
                    link: item.url,
                    attachments: attachments.map(attachment => ({
                        attachments: attachment.attachments ? `${attachmentBaseUrl}${attachment.attachments}` : null,
                        attachment_type: attachment.attachment_type || null,
                        thumb_image: attachment.thumb_image ? `${thumbBaseUrl}${attachment.thumb_image}` : null
                    }))
                };
            })
        };
    };

    const handleShowModal = () => {
        setPreviewData(preparePreviewData());
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    const openLinkInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const handleVideoClick = (videoUrl) => {
        window.open(videoUrl, "_blank", "noopener,noreferrer");
    };

    return (
        <>
            <JqueryLoad />
            <Hdashboard />
            <Helmet>
                <style>
                    {`
          .form-control:disabled, .form-control[readonly] {
            background-color: #ffffff;
            opacity: 1;
          }
          `}
                </style>
            </Helmet>
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div>
                            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span className="ms-2">Back</span>
                                </span>
                            </a>
                            <div className="row">
                                <div className="col-xl-8 mx-4">
                                    <div className="mb-4">
                                        <p className="d-flex fs-3 fw-600">Add News Letter</p>

                                    </div>
                                    <div className='card border-purple p-4 mb-3'>
                                        <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Add News Letter Title</label>
                                                <div className="position-relative class_title">
                                                    <input
                                                        type="text"
                                                        {...register('newsletter_title')}
                                                        maxLength={100}
                                                        name="newsletter_title"
                                                        className="form-control"
                                                        placeholder="News Letter Title"
                                                    />
                                                    <span className="position-absolute word_count">
                                                        {100 - (watch('newsletter_title')?.split('')?.length ?? 0)} Words
                                                    </span>
                                                    <p className="mt-2 font-bold col-red">
                                                        <span style={{ color: 'red' }}>{errors.newsletter_title?.message}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            {newsletterDetails.map((lesson, index) => (
                                                <div key={index}>
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Add NewsLetter Description</label>
                                                        <div className="position-relative class_summary">
                                                            <Controller
                                                                name={`newsletterdata.${index}.description`}
                                                                control={control}
                                                                defaultValue=""
                                                                render={({ field }) => (
                                                                    <Editor
                                                                        content={field.value}
                                                                        onChange={(value) => {
                                                                            field.onChange(value);
                                                                            handleDescriptionChange(index, value);
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            <p className="mt-2 font-bold col-red">
                                                                <span style={{ color: 'red' }}>{errors.newsletterdata?.[index]?.description?.message}</span>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="form-group mb-4 attach_box">
                                                        <label className="form-label">Add Attachments</label>
                                                        <div className="position-relative">
                                                            <textarea type="text" className="form-control" rows={1} value={AttachmentData[`attachment_${index}`]
                                                                ?.filter(item => item.attachments)
                                                                .map(item => item.attachments)
                                                                .join('\n')}
                                                                readOnly
                                                            />
                                                            <div className="att_icon_list position-absolute">
                                                                <ul className="d-flex align-items-center justify-content-end">
                                                                    <li>
                                                                        <label htmlFor={`attach1_${index}`}>
                                                                            <a>
                                                                                <img src="./assets/images/attach1.png" alt="attach1" />
                                                                            </a>
                                                                        </label>
                                                                        <input type="file" id={`attach1_${index}`} onInput={(e) => {
                                                                            const file = e.target.files[0];
                                                                            const fileType = file.type.startsWith('image') ? 'image' : 'video';
                                                                            handleImageChange(file, index, 0, fileType);
                                                                        }} {...register("attachments")} accept="image/*,video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                    </li>
                                                                    <li>
                                                                        <label htmlFor={`attach2_${index}`}>
                                                                            <a >
                                                                                <img src="./assets/images/attach2.png" alt="attach2" />
                                                                            </a>
                                                                        </label>
                                                                        <input type="file" id={`attach2_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                    </li>
                                                                    <li>
                                                                        <label htmlFor={`attach3_${index}`}>
                                                                            <a>
                                                                                <img src="./assets/images/attach3.png" alt="attach3" />
                                                                            </a>
                                                                            <input type="file" id={`attach3_${index}`}  {...register("attachments")} accept="image/*" onInput={(e) => handleImageChange(e.target.files[0], index, 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                        </label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <p className="mt-2 font-bold col-red">
                                                            <span style={{ color: 'red' }}>
                                                                {errors.newsletterdata?.[index]?.attachments?.message}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Add URL</label>
                                                        <div className="d-flex align-items-center">
                                                            <input
                                                                name='url'
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter URL"
                                                                {...register(`newsletterdata.${index}.url`)}
                                                                onChange={(e) => handleUrlInputChange(index, e.target.value)}
                                                            />
                                                        </div>
                                                        <p className="mt-2 font-bold col-red">
                                                            <span style={{ color: 'red' }}>{errors.newsletterdata?.[index]?.url?.message}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="d-flex justify-content-end mt-3">
                                                <a className="text-decoration-underline text-purple" onClick={handleAddDescription}>
                                                    + Add another Description
                                                </a>
                                            </div>

                                            <div className="form-group mb-4">
                                                <label className="form-label mb-2">When should we send this email?</label>
                                                <div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            value="now"
                                                            checked={sendNow}
                                                            onChange={() => setSendNow(true)}
                                                        />
                                                        <label className="form-check-label ms-2 mt-1">
                                                            Send Now
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            value="schedule"
                                                            checked={!sendNow}
                                                            onChange={() => setSendNow(false)}
                                                        />
                                                        <label className="form-check-label ms-2 mt-1">
                                                            Schedule a Time
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            {!sendNow && (
                                                <div className="form-group mb-4">
                                                    <label className="form-label">Select Date and Time</label>
                                                    <div className="d-flex flex-column flex-md-row align-items-start">
                                                        <div className="col-md-3 mb-3 mb-md-0">
                                                            <div className="input-group">
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    {...register('schedule_date')}
                                                                    onChange={(e) => {
                                                                        setScheduleDate(e.target.value);
                                                                        clearErrors("schedule_date");
                                                                    }}
                                                                    min={new Date().toISOString().split("T")[0]} // Disable past dates
                                                                />
                                                            </div>
                                                            {errors.schedule_date && (
                                                                <div>
                                                                    <span style={{ color: 'red' }} className="mt-2">{errors.schedule_date.message}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-3 ms-3">
                                                            <div className="input-group">
                                                                <input
                                                                    type="time"
                                                                    className="form-control"
                                                                    {...register('schedule_time')}
                                                                    onChange={(e) => {
                                                                        const timeValue = e.target.value;
                                                                        const formattedTime = `${timeValue}:00`; // Add seconds as 00
                                                                        setScheduleTime(formattedTime);
                                                                        clearErrors("schedule_time");
                                                                    }}
                                                                    min={scheduleDate === new Date().toISOString().split("T")[0]
                                                                        ? new Date().toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })
                                                                        : '00:00'} // Disable past times if current date is selected
                                                                />
                                                            </div>
                                                            {errors.schedule_time && (
                                                                <div>
                                                                    <span style={{ color: 'red' }} className="mt-2">{errors.schedule_time.message}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="button mt-5 d-flex">
                                                <span className="btn-theme bg-yellow text-center fw-600 d-block" onClick={handleShowModal}>
                                                    Preview
                                                </span>
                                                <button className="btn-theme bg-yellow text-center fw-600 d-block ms-3" >
                                                    Create News Letter
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Preview News Letter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {previewData?.title ? (
                        <div className="newsletter-details card border-purple mb-3">
                            <p className="fw-500 fs-4 text-center mt-3 text-purple">{previewData.title}</p>
                            <div className="descriptions">
                                {previewData.description.map(desc => (
                                    <div key={desc.id} className="description px-4 py-2">
                                        {desc.attachments && (
                                            <div className="attachments-container mt-1">
                                                {desc.attachments.map((attachment, index) => (
                                                    <div key={index} className="attachment mt-1 position-relative col-md-12">
                                                        {attachment ? (
                                                            <>
                                                                {attachment.attachment_type === "image" ? (
                                                                    <img
                                                                        src={attachment.attachments}
                                                                        alt="Attachment"
                                                                        className="img-fluid"
                                                                        style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                                                                    />
                                                                ) : attachment.attachment_type === "video" ? (
                                                                    <div className="position-relative" style={{ width: '100%' }}>
                                                                        <img
                                                                            src={attachment.thumb_image}
                                                                            alt="Thumbnail"
                                                                            className="img-fluid"
                                                                            style={{ width: '100%', height: 'auto', borderRadius: '8px', cursor: 'pointer' }}
                                                                            onClick={() => handleVideoClick(attachment.attachments)}
                                                                        />
                                                                        <div
                                                                            className="play-icon position-absolute top-50 start-50 translate-middle"
                                                                            onClick={() => handleVideoClick(attachment.attachments)}
                                                                            style={{ width: '50px', height: '50px' }}
                                                                        >
                                                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <circle cx="25" cy="25" r="25" fill="rgba(0, 0, 0, 0.6)" />
                                                                                <path d="M15 12.5V37.5L37.5 25L15 12.5Z" fill="white" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <a href={attachment.attachments} target="_blank" rel="noopener noreferrer">View Attachment</a>
                                                                )}
                                                            </>
                                                        ) : null}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <div dangerouslySetInnerHTML={{ __html: desc.description }} className="mt-3" />
                                        {desc.link && (
                                            <div className="mt-4 mb-2">
                                                <button
                                                    onClick={() => openLinkInNewTab(desc.link)}
                                                    // className="image-button"
                                                    className='btn-theme col-md-12'
                                                >
                                                    Learn More
                                                </button>
                                            </div>
                                        )}

                                        <hr style={{ "border": "none", "border-top": "2px solid #5d0968" }} className="mt-3" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <p className="text-purple fw-500 fs-4 ms-5 mt-3">No data available</p>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}

