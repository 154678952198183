import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { admin_permission_detail, studentParentList, userBlockUnblock, userDelete } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import FontAwesome from "react-fontawesome";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import SwalOld from "sweetalert"
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import Swal from "sweetalert2";
import Image from "../../../user.png";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import customStyles from "../../../utils/dataTableStyles";

export default function Student_Parent({ isLoader }) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [activeActionPending, setActiveActionPending] = useState(false);
    const AdminId = localStorage.getItem("aID")
    const [permissions, setPermission] = useState([]);
    const role = localStorage.getItem("adminRole");
    const [search, setSearch] = useState('')

    const GetStudentParentData = () => {
        isLoader(true)
        studentParentList({ search: search }).then((response) => {
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setData(dataWithRowCount);
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
            isLoader(false)
        })
        isLoader(false)
    }

    const handleView = (id) => {
        navigate('/user_details', { state: { id: id } })
    }

    const handleDelete = async (id) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete?",
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    isLoader(true)
                    userDelete({ id: id }).then((response) => {
                        if (response.code == 1) {
                            setData((prevData) => prevData.filter((user) => user.id !== id))
                            TOAST_SUCCESS(response.message);
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    })
                } catch (error) {
                    isLoader(false)
                    TOAST_ERROR("Error deleting user");
                }
            }
        });
    };

    const getViewLabel = (category) => {
        if (category === "after_school") {
            return "View Class";
        } else if (category === "below_18") {
            return "View Learner";
        } else if (category === "above_18") {
            return "-";
        } else {
            return "";
        }
    };

    const handleView2 = (id, category) => {
        if (category === "after_school") {
            navigate('/class-details', { state: { orgid: id } });
        } else if (category === "below_18") {
            navigate('/parent-learner', { state: { parentid: id } });
        }
    }

    const handleView3 = (id ,name) => {
        navigate('/subscription-class-details', { state: { user_id: id ,learner_name : name} });
    }

    const clickActive = (id, is_active, email, full_name) => {
        const actionText = is_active === 1 ? 'block' : 'unblock';
        setActiveActionPending(true);
        SwalOld({
            title: 'Are you sure?',
            text: 'You want to ' + actionText + ' this user ?',
            icon: 'warning',
            buttons: {
                confirm: {
                    text: is_active === 1 ? 'Block' : 'Unblock',
                    className: 'btn-success'
                },
                cancel: 'Cancel',
            },
            dangerMode: true,
        })
            .then((activate) => {
                setActiveActionPending(false);
                if (activate) {
                    if (is_active === 1) {
                        HandleMakeInactive(id, full_name, email);
                    } else {
                        HandleMakeActive(id, full_name, email);
                    }
                } else {
                    GetStudentParentData();
                }
            });
    }

    const HandleMakeActive = async (id, full_name, email) => {
        let catid = `${id}`
        let objCategory = {
            id: catid,
            is_active: "1",
            name: full_name,
            email: email
        };
        userBlockUnblock((objCategory)).then((response) => {
            if (response.code == "1") {
                updateLearnerStatus(id, 1); // Update locally
                TOAST_SUCCESS(response.message);
                GetStudentParentData();
            } else {
                TOAST_ERROR(response.message);
            }
        })
    };

    const HandleMakeInactive = async (id, full_name, email) => {
        let catid = `${id}`
        let objCategory = {
            id: catid,
            is_active: "0",
            name: full_name,
            email: email
        };
        userBlockUnblock((objCategory)).then((response) => {
            if (response.code == "1") {
                updateLearnerStatus(id, 0); // Update locally
                TOAST_SUCCESS(response.message);
                GetStudentParentData();
            } else {
                TOAST_ERROR(response.message);
            }
        })
    };

    const updateLearnerStatus = (id, status) => {
        setData(prevData =>
            prevData.map(learner =>
                learner.id === id ? { ...learner, is_active: status } : learner
            )
        );
    };

    const subAdminPermission = () => {
        admin_permission_detail({ id: AdminId })
            .then((response) => {
                if (response.code == 1) {
                    setPermission(response.data);
                } else {
                    setPermission([]);
                }
            })
            .catch((error) => {
                setPermission([]);
            });
    }

    useEffect(() => {
        if (localStorage.getItem("AToken")) {
            GetStudentParentData();
            if (role !== "S") {
                subAdminPermission();
            }
        } else {
            return;
        }
    }, [search])

    const studentParentPermissions = permissions.find(permission => permission.menu_name === "Student Parents");

    // Check if status,deleting is allowed for "Student Parents"
    const isDeleteAllowed = studentParentPermissions && studentParentPermissions.delete === 1;
    const isStatusAllowed = studentParentPermissions && studentParentPermissions.status === 1;

    const getViewCategory = (category) => {
        if (category === "after_school") {
            return "Schools-After School Centers";
        } else if (category === "below_18") {
            return "Parent";
        } else if (category === "above_18") {
            return "Over 18";
        } else {
            return "";
        }
    };

    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "70px"
        },
        {
            name: 'Registered Date',
            selector: row => {
                const date = new Date(row.created_at);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const formattedMonth = month < 10 ? `0${month}` : month;
                const formattedDay = day < 10 ? `0${day}` : day;
                const year = date.getFullYear();
                return `${formattedDay}-${formattedMonth}-${year}`;
            },
            sortable: true,
            width: "160px"
        },
        {
            name: 'Id Code',
            selector: row => row.code_generate,
            sortable: true,
            width: "110px",
            cell: row => row.code_generate == null ? "0000" : row.code_generate
        },
        {
            name: 'Image',
            cell: (data) => (
                <img
                    src={data.profile_image ? data.profile_image : Image}
                    style={{ width: '50px', height: '50px' }}
                />
            ),
            sortable: true,
            width: "100px"
        },
        {
            name: 'Main Role',
            selector: row => row.category,
            sortable: true,
            width: "130px",
            cell: (row) => getViewCategory(row.category)
        },
        {
            name: 'Sub Role',
            cell: (row) => (
                <button style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }} onClick={() => handleView2(row.id, row.category)}>{getViewLabel(row.category)}</button>
            ),
            sortable: true,
            width: "140px"
        },
        {
            name: 'Name',
            selector: row => row.full_name,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "210px"
        },
        {
            name: 'Subscriptions',
            cell: (row) => (
                row.category === "above_18" ? (
                    <button style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }} onClick={() => handleView3(row.id,row.full_name)}>View Class</button>
                ) : (
                    "-"
                )
            ),
            sortable: true,
            width: "170px"
        },
        {
            name: 'Actions',
            width: "130px",
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    <div className="col me-2">
                        <FontAwesome
                            className="fs-5 fa-solid fa-eye  mx-2 my-2 bg-light text-secondary"
                            name="view"
                            onClick={() => handleView(row.id)}
                        ></FontAwesome>
                    </div>

                    {(role === "S" || isDeleteAllowed) &&
                        <div className="col me-2">
                            <FontAwesome
                                name="delete"
                                className="fs-5 fa-solid fa-trash  mx-2 my-2 bg-light text-danger"
                                onClick={() => handleDelete(row.id)}
                            ></FontAwesome>
                        </div>
                    }
                </div>
            ),
        },
        {
            name: role == "S" || isStatusAllowed ? 'Block/Unblock' : '',
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    {(role === "S" || isStatusAllowed) && (
                        <BootstrapSwitchButton
                            checked={row.is_active === 1}
                            onlabel='Block'
                            offlabel='Unblock'
                            width={90}
                            size="sm"
                            offstyle="light text-dark"
                            onChange={() => clickActive(row.id, row.is_active, row.full_name, row.email)}
                        />
                    )}
                </div>
            ),
        },
    ]

    return <>
        <JqueryLoad />
        <Hdashboard />
        {localStorage.getItem("AToken") && (
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="index.php" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <>
                        <div className="fs-3 fw-500 mt-4 ms-4 mb-2">
                            Learner Parent Data
                        </div>
                        <div className="d-flex justify-content-end align-items-center mb-3 search-container">
                            <div className="row me-5">
                                <div className="col">
                                    <CInputGroup>
                                        <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                        <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                            <FontAwesome className="fa-solid fa-search" name="search" />
                                        </CButton>
                                    </CInputGroup>
                                </div>
                            </div>
                        </div>
                        <div className="px-5 mt-1">
                                {data.length === 0 ? (
                                    <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No Data Found</p>
                                ) : (
                                    data && data.length > 0 && (
                                        <DataTable
                                            persistTableHead
                                            columns={columns}
                                            data={data}
                                            pagination
                                            customStyles={customStyles}
                                            className="custom-data-table"
                                        />
                                    )
                                )}
                        </div>
                    </>
                </div>
            </main>
        )}
    </>
}

