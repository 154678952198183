import { useNavigate, useLocation } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { AccessAccount, CoupounDetails, CoupounList, get_contactus_list } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import FontAwesome from "react-fontawesome";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import { Link } from "react-router-dom";
import customStyles from "../../../utils/dataTableStyles";
import { encryptURL } from "../../../api/apiClient";
import { TOAST_ERROR } from "../../../utils/common.service";

export default function CouponDetails({ isLoader }) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [search, setSearch] = useState('');
    const location = useLocation()
    const couponId = location.state && location.state.coupan_id;

    const handleGetData = () => {
        isLoader(true)
        CoupounDetails({ coupan_id: couponId }).then((response) => {
            console.log('response :', response);
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1}));
                setData(dataWithRowCount);
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        })
    }

    const handleRedirect = (learnerId, learnerName ,userType) => {
        isLoader(true);
        AccessAccount({ user_id: learnerId })
            .then((response) => {
                if (response.code === "1") {
                    localStorage.setItem("PAtoken", response.data[0]?.token);
                    localStorage.setItem("MAisLogin", true);
                    localStorage.setItem("userType", userType);
                    localStorage.setItem("PAid", learnerId);
                    localStorage.setItem("MAname", learnerName);
                    localStorage.setItem("role", "admin");

                    isLoader(false);

                    const url = `https://studentscholars.com/ewallet`;
                    window.open(url, '_blank');

                } else {
                    isLoader(false);
                    TOAST_ERROR("ERROR..!!");
                }
            })
            .catch((error) => {
                TOAST_ERROR("ERROR..!!");
                isLoader(false);
            });
    };

    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "100px"
        },
        {
            name: 'Learner Name',
            selector: row => row.learner_name,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Coupon Name',
            selector: row => row.coupan_name,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Coupon Number',
            selector: row => row.coupan_number,
            sortable: true,
            width: "210px"
        },
        {
            name: 'Amount Percentage',
            selector: row => row.amount_percentages ? row.amount_percentages : "-",
            sortable: true,
            width: "200px"
        },
        {
            name: 'Amount Received',
            selector: row => row.amount_received,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Purchased Date',
            selector: row => {
                const date = new Date(row.purchase_date);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const formattedMonth = month < 10 ? `0${month}` : month;
                const formattedDay = day < 10 ? `0${day}` : day;
                const year = date.getFullYear();
                return `${formattedDay}-${formattedMonth}-${year}`;
            },
            sortable: true,
            width: "250px"
        },
        {
            name: 'URL',
            cell: (row) => (
                <button style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                    onClick={() => handleRedirect(row.user_id, row.learner_name , row.category)}
                >Click Here</button>
            ),
            sortable: true,
            width: "90px"
        }
    ]

    useEffect(() => {
        handleGetData()
    }, [search])

    const handleBack = () => {
        navigate(-1);
    };

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <Link className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </Link>
                            <Link to="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </Link>
                        </div>
                    </div>
                </header>
                <span onClick={handleBack} className="mx-4 mt-3 back-button">
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313"></path>
                    </svg>
                    <span className="ms-2">Back</span>
                </span>
                <>
                    <div className="fs-3 fw-500 mt-2 ms-4">
                        Coupons Details
                    </div>
                    <div className="d-flex justify-content-end align-items-center mb-3 search-container">
                        <div className="row me-5">
                            <div className="col">
                                <CInputGroup>
                                    <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                    <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                        <FontAwesome className="fa-solid fa-search" name="search" />
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>
                    </div>
                    <div className="px-5 mt-1">
                        {(
                            data && data.length > 0 ? (
                                <DataTable title="" columns={columns} data={data} pagination customStyles={customStyles} />
                            ) : (
                                <p className="text-center text-purple fw-500 fs-3 mt-5">No Data Found</p>
                            )
                        )}
                    </div>
                </>
            </div>
        </main>
    </>
}