import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { admin_permission_detail, learnerBlockUnblock, learnerDelete, parentLearnerList } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import FontAwesome from "react-fontawesome";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import Swal from "sweetalert2";
import SwalOld from "sweetalert"
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import Image from "../../../user.png";
import customStyles from "../../../utils/dataTableStyles";

export default function Parent_Learner({ isLoader }) {

    const [learnerData, setLearnerData] = useState([])
    const [permissions, setPermission] = useState([]);
    const [activeActionPending, setActiveActionPending] = useState(false);
    const role = localStorage.getItem("adminRole");
    const AdminId = localStorage.getItem("aID")
    const navigate = useNavigate()
    const location = useLocation()
    const parentId = location.state && location.state.parentid
    const [search, setSearch] = useState('');

    const GetParentLearnerData = () => {
        isLoader(true)
        parentLearnerList({ id: parentId, search: search }).then((response) => {
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setLearnerData(dataWithRowCount);
                isLoader(false)
            } else {
                setLearnerData([])
                isLoader(false)
            }
            isLoader(false)
        });
        isLoader(false)
    }

    const handleDelete = async (id) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete?",
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    isLoader(true)
                    learnerDelete({ id: id }).then((response) => {
                        if (response.code == 1) {
                            setLearnerData((prevData) => prevData.filter((user) => user.id !== id))
                            TOAST_SUCCESS(response.message);
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    })
                } catch (error) {
                    isLoader(false)
                    TOAST_ERROR("Error deleting learner");
                }
            }
        });
    };

    const handleView = (id) => {
        navigate('/learnerdetails', { state: { LearnerId: id } })
    }

    const handleView3 = (id,learner_name) => {
        navigate('/learner-subscription-class-details', { state: { learner_id: id , LearnerName:learner_name} });
    }

    const clickActive = (id, is_active, learner_name, learner_email) => {
        const actionText = is_active ? 'block' : 'unblock';
        setActiveActionPending(true); // Set pending action state

        SwalOld({
            title: 'Are you sure?',
            text: `You want to ${actionText} this learner?`,
            icon: 'warning',
            buttons: {
                confirm: {
                    text: is_active ? 'Block' : 'Unblock',
                    className: 'btn-success'
                },
                cancel: 'Cancel',
            },
            dangerMode: true,
        }).then((activate) => {
            setActiveActionPending(false); // Reset pending action state after swal interaction
            if (activate) {
                if (is_active) {
                    HandleMakeInactive(id, learner_email, learner_name);
                } else {
                    HandleMakeActive(id, learner_email, learner_name);
                }
            } else {
                // If canceled, no action needed
            }
        });
    };
    const HandleMakeActive = async (id, learner_email, learner_name) => {
        try {
            const objCategory = {
                id: id,
                is_active: "1",
                name: learner_name,
                email: learner_email
            };
            isLoader(true);
            const response = await learnerBlockUnblock(objCategory);
            if (response.code === "1") {
                updateLearnerStatus(id, 1); // Update locally
                TOAST_SUCCESS(response.message);
                isLoader(false);
            } else {
                isLoader(false);
                TOAST_ERROR(response.message);
            }
        } catch (error) {
            isLoader(false);
            TOAST_ERROR("Error occurred while updating learner status.");
        } finally {
            isLoader(false);
            GetParentLearnerData(); // Refresh data after updating status
        }
    };

    const HandleMakeInactive = async (id, learner_email, learner_name) => {
        try {
            const objCategory = {
                id: id,
                is_active: "0",
                name: learner_name,
                email: learner_email
            };
            isLoader(true);
            const response = await learnerBlockUnblock(objCategory);
            if (response.code === "1") {
                updateLearnerStatus(id, 0); // Update locally
                TOAST_SUCCESS(response.message);
                isLoader(false);
            } else {
                TOAST_ERROR(response.message);
                isLoader(false);
            }
        } catch (error) {
            TOAST_ERROR("Error occurred while updating learner status.");
            isLoader(false);
        } finally {
            isLoader(false);
            GetParentLearnerData(); // Refresh data after updating status
        }
    };

    const updateLearnerStatus = (id, status) => {
        setLearnerData(prevData =>
            prevData.map(learner =>
                learner.id === id ? { ...learner, is_active: status } : learner
            )
        );
    };

    const subAdminPermission = () => {
        admin_permission_detail({ id: AdminId })
            .then((response) => {
                if (response.code == 1) {
                    setPermission(response.data);
                } else {
                    setPermission([]);
                }
            })
            .catch((error) => {
                setPermission([]);
            });
    }

    useEffect(() => {
        GetParentLearnerData()
        if (role !== "S") {
            subAdminPermission();
        }
    }, [search])

    const parentLearnerPermission = permissions.find(permission => permission.menu_name === "Student Parents");

    // Check if status,deleting is allowed for "Student Parents"
    const isDeleteAllowed = parentLearnerPermission && parentLearnerPermission.delete === 1;
    const isStatusAllowed = parentLearnerPermission && parentLearnerPermission.status === 1;

    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "70px"
        },
        {
            name: 'Registered Date',
            selector: row => {
                const date = new Date(row.created_at);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const formattedMonth = month < 10 ? `0${month}` : month;
                const formattedDay = day < 10 ? `0${day}` : day;
                const year = date.getFullYear();
                return `${formattedDay}-${formattedMonth}-${year}`;
            },
            sortable: true,
            width: "170px"
        },
        {
            name: 'Image',
            cell: (learnerData) => (
                <img
                    src={learnerData.learner_profile_img ? learnerData.learner_profile_img : Image}
                    style={{ width: '50px', height: '50px' }}
                />
            ),
            sortable: true,
            width: "130px"
        },
        {
            name: 'Learner Name',
            selector: row => row.learner_name,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Learner Age',
            selector: row => row.learner_age,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Learner Email',
            selector: row => row.learner_email,
            sortable: true,
            width: "250px"
        },
        {
            name: 'Subscriptions',
            cell: (row) => (
                <button style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }} onClick={() => handleView3(row.id,row.learner_name)}>View Class</button>
            ),
            sortable: true,
            width: "250px"
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    <div className="col me-2">
                        <FontAwesome
                            name="view"
                            className="fs-5 fa-solid fa-eye  mx-2 my-2 bg-light text-secondary"
                            onClick={() => handleView(row.id)}
                        ></FontAwesome>
                    </div>

                    {(role === "S" || isDeleteAllowed) &&
                        <div className="col me-2">
                            <FontAwesome
                                name="delete"
                                className="fs-5 fa-solid fa-trash  mx-2 my-2 bg-light text-danger"
                                onClick={() => handleDelete(row.id)}
                            ></FontAwesome>
                        </div>
                    }
                </div>
            ),
        },
        {
            name: (role === "S" || isStatusAllowed) ? 'Block/Unblock' : '',
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    {(role === "S" || isStatusAllowed) && (
                        <BootstrapSwitchButton
                            checked={row.is_active == 1}
                            onlabel='Block'
                            offlabel='UnBlock'
                            width={90}
                            size="sm"
                            offstyle="light text-dark"
                            onChange={() => {
                                clickActive(row.id, row.is_active, row.learner_name, row.learner_email);
                            }}
                        />
                    )}
                </div>
            ),
        },
    ]

    const handleBack = () => {
        navigate(-1);
    };

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex main_sec">

                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>

                <span onClick={handleBack} className="mx-4 my-3 mt-3">
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                    </svg>
                    <span className="ms-2">Back</span>
                </span>
                <>
                    <div className="fs-3 fw-500 ms-4 mb-2">
                        Learner Data
                    </div>
                    <div className="d-flex justify-content-end align-items-center mb-3 search-container">
                        <div className="row me-5">
                            <div className="col">
                                <CInputGroup>
                                    <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                    <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                        <FontAwesome className="fa-solid fa-search" name="search" />
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>
                    </div>
                    <div className="px-5 mt-1">
                        {learnerData.length === 0 ? (
                            <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No Data Found</p>
                        ) : (
                            learnerData && learnerData.length > 0 && (
                                <DataTable
                                    persistTableHead
                                    columns={columns}
                                    data={learnerData}
                                    pagination
                                    customStyles={customStyles}
                                    className="custom-data-table"
                                />
                            )
                        )}
                    </div>
                </>
            </div>
        </main>
    </>
}