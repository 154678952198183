import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { AccessAccount, Complaint_Details } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import customStyles from "../../../utils/dataTableStyles";
import { TOAST_ERROR } from "../../../utils/common.service";

export default function ComplaintDetails({isLoader}) {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const teacherId = location.state && location.state.teacherId;

    const complaintDetails = () => {
        isLoader(true);
        Complaint_Details({ teacher_id: teacherId }).then((response) => {
        console.log('response :', response);
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setData(dataWithRowCount);
                isLoader(false);
            } else {
                setData([]);
                isLoader(false);
            }
        });
    };

    const handleBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        complaintDetails();
    }, []);

    const handleRedirect = (id, category, name,class_link) => {
        isLoader(true);
        AccessAccount({ user_id: id })
            .then((response) => {
                if (response.code === "1") {
                    localStorage.setItem("PAtoken", response.data[0]?.token);
                    localStorage.setItem("MAisLogin", true);
                    localStorage.setItem("userType", category);
                    localStorage.setItem("PAid", id);
                    localStorage.setItem("MAname", name);
                    localStorage.setItem("role","admin");
                    isLoader(false);

                    // Open the URL in a new tab
                    if (class_link) {
                        window.open(class_link, '_blank');
                    }

                } else {
                    isLoader(false);
                    TOAST_ERROR("ERROR..!!");
                }
            })
            .catch((error) => {
                TOAST_ERROR("ERROR..!!");
                isLoader(false);
            });
    };

    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "60px"
        },
        {
            name: (
                <>
                    Complainer<br />Name
                </>
            ),
            selector: row => row.complainer_name,
            sortable: false,
            width: "160px"
        },
        {
            name: 'Feedback Type',
            selector: row => row.feedback_type,
            sortable: true,
            width: "500px"
        },
        {
            name: 'Feedback Details',
            selector: row => row.feedback_details,
            sortable: true,
            width: "300px"
        },
        {
            name: 'Attachment 1',
            selector: row => row.attachments && row.attachments[0] ? <a href={row.attachments[0].attachments} download style={{ color: 'rgb(87, 8, 97)' }}>Click Here</a> : '-',
            sortable: false,
            width: "110px"
        },
        {
            name: 'Attachment 2',
            selector: row => row.attachments && row.attachments[1] ? <a href={row.attachments[1].attachments} download style={{ color: 'rgb(87, 8, 97)' }}>Click Here</a> : '-',
            sortable: false,
            width: "110px"
        },
        {
            name: 'Attachment 3',
            selector: row => row.attachments && row.attachments[2] ? <a href={row.attachments[2].attachments} download style={{ color: 'rgb(87, 8, 97)' }}>Click Here</a> : '-',
            sortable: false,
            width: "110px"
        },
        {
            name: 'View Classroom',
            sortable: false,
            width: "150px",
            cell: row => <span style={{cursor: 'pointer'}} className="text-purple" onClick={() => handleRedirect(row.user_id, row.user_category, row.complainer_name , row.class_link)}>Click here</span>
        }
    ];

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>

                <span onClick={handleBack} className="mx-4 my-3 mt-4">
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                    </svg>

                    <span className="ms-2">Back</span>
                </span>
                <>
                    <div className="fs-3 fw-500 ms-4 mb-2">
                        Complaint Data
                    </div>

                    <div className="px-5 mt-4">
                        {data && data.length > 0 ? (
                            <>
                                <DataTable columns={columns} data={data} pagination customStyles={customStyles}/>
                            </>
                        ) : (
                            <>
                                <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No data Found</p>
                            </>
                        )}
                    </div>
                </>
            </div>
        </main>
    </>
}
