import { useLocation, useNavigate } from "react-router-dom";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import Hdashboard from "../../../components/Hdashboard";
import { useEffect, useRef, useState } from "react";
import { admin_detail, admin_permission_detail, menuList, update_admin_permission, update_sub_admin } from "../../../api/apiHandler";
import * as Yup from "yup";
import { TOAST_ERROR, TOAST_SUCCESS, convertToBase64 } from "../../../utils/common.service";
import { useFormik } from "formik";
import { uploadImageOnAWS } from "../../aws/Service";

export default function SubAdminList({isLoader}) {

    const fileRef = useRef();
    const [menu, setMenu] = useState([]);
    const [image, setImage] = useState(null);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const navigate = useNavigate();

    const location = useLocation()
    const subAdminId = location.state && location.state.subAdminId;

    useEffect(() => {
        isLoader(true)
        admin_detail({ id: subAdminId })
            .then((response) => {
                if (response.code === '1') {
                    subAdminForm.setValues({
                        name: response.data.name,
                        email: response.data.email,
                        profile_image: response.data.profile_image

                    });

                    if (response.data.profile_image) {
                        setImage(response.data.profile_image);
                    }
                    isLoader(false)
                } else {
                    console.error("Error fetching admin details:", response.message);
                    isLoader(false)
                }
            })
            .catch((error) => {
                console.error("Error fetching admin details:", error);
                isLoader(false)
            });
        isLoader(true)
        admin_permission_detail({ id: subAdminId })
            .then((response) => {
                if (response.code == 1) {
                    response.data.map((item, index) => {
                        item.add === 1 ? item.add = true : item.add = false;
                        item.edit === 1 ? item.edit = true : item.edit = false;
                        item.delete === 1 ? item.delete = true : item.delete = false;
                        item.view === 1 ? item.view = true : item.view = false;
                        item.status === 1 ? item.status = true : item.status = false;
                        item.anyTrue = Object.values(item).some(value => value === true);
                    })
                    setSelectedPermissions(response.data)
                    isLoader(false)
                } else {
                    console.error("Error fetching admin permissions:", response.message);
                    isLoader(false)
                }
            })
            .catch((error) => {
                console.error("Error fetching admin permissions:", error);
                isLoader(false)
            });

    }, [subAdminId]);

    const handleBack = () => {
        navigate(-1);
    };


    const fetchMenuList = () => {
        menuList()
            .then((response) => {
                if (response.code === '1') {
                    setMenu(response.data);
                } else {
                    console.error("Error fetching menu:", response.message);
                }
            })
            .catch((error) => {
                console.error("Error fetching menu:", error);
            });
    };

    useEffect(() => {
        fetchMenuList();
    }, []);

    const subAdminSchema = Yup.object({
        name: Yup.string().required("Please Enter Name").matches(/^[aA-zZ\s]+$/, 'Only characters are allowed in name'),
        email: Yup.string().required("Please Provide email").email("Please enter valid email address").matches(
            /^[^\W\d][\w\._%+-]+@[A-Za-z\d.-]+\.[A-Za-z]{2,3}$/,
            'Please enter valid email address',
        ),
        profile_image: Yup.mixed().required("Please Upload Image"),
        add_permission: Yup.array()
    });

    const subAdminForm = useFormik({
        initialValues: {
            name: '',
            email: '',
            profile_image: null,
            add_permission: []
        },
        validationSchema: subAdminSchema,
        onSubmit: async (values) => {

            if (subAdminForm.values.profile_image != null && subAdminForm.values.profile_image instanceof Blob) {
                var profile_image = await uploadImageOnAWS(subAdminForm.values.profile_image, "admin");
            }

            var obj = {
                id: subAdminId,
                name: subAdminForm.values.name,
                email: subAdminForm.values.email,
            };
            if (subAdminForm.values.profile_image !== null) {
                obj.profile_image = profile_image;
            }

            update_sub_admin(obj).then((response) => {
                if (response.code == 1) {
                    const permissions = selectedPermissions.map(permission => ({
                        admin_id: subAdminId,
                        menu_name: permission.menu_name,
                        add: permission.add === true ? 1 : 0,
                        edit: permission.edit === true ? 1 : 0,
                        delete: permission.delete === true ? 1 : 0,
                        view: permission.view === true ? 1 : 0,
                        status: permission.status === true ? 1 : 0
                    }));
                    update_admin_permission(permissions).then((response1) => {
                        if (response1.code == 1) {
                            TOAST_SUCCESS(response.message);
                            navigate('/sub_admin_list');
                        } else {
                            TOAST_ERROR(response1.message);
                        }
                    });
                } else {
                    TOAST_ERROR(response.message);
                }
            });
        },
    });

    const uploadImage = () => {
        fileRef.current.click();
    };

    const uploadImageEvent = async (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const bufferImage = await convertToBase64(e.target.files[0]);
            setImage(bufferImage);
            subAdminForm.setFieldValue('profile_image', e.target.files[0]);
        }
        else {
            setImage(null);
        }
    };

    const displayError = (fieldName) => subAdminForm.touched[fieldName] && subAdminForm.errors[fieldName];

    const handlePermissionClick = (menuItem, index, event) => {
        const isChecked = event.target.checked;
        const updatedPermissions = [...selectedPermissions];
        const selectedPermissionIndex = updatedPermissions.findIndex(permission => permission.menu_name === menuItem.menu_name);
        if (selectedPermissionIndex !== -1) {
            updatedPermissions[selectedPermissionIndex].add = menuItem.add == 1 ? isChecked : 0;
            updatedPermissions[selectedPermissionIndex].edit = menuItem.edit == 1 ? isChecked : 0;
            updatedPermissions[selectedPermissionIndex].delete = menuItem.delete == 1 ? isChecked : 0;
            updatedPermissions[selectedPermissionIndex].view = menuItem.view == 1 ? isChecked : 0;
            updatedPermissions[selectedPermissionIndex].status = menuItem.status == 1 ? isChecked : 0;
        } else {
            const newPermission = {
                menu_name: menuItem.menu_name,
                add: menuItem.add == 1 ? isChecked : 0,
                edit: menuItem.edit == 1 ? isChecked : 0,
                delete: menuItem.delete == 1 ? isChecked : 0,
                view: menuItem.view == 1 ? isChecked : 0,
                status: menuItem.status == 1 ? isChecked : 0
            };
            updatedPermissions.push(newPermission);
        }
        setSelectedPermissions(updatedPermissions);
    };

    const handleChildPermissionClick = (menuName, permissionType) => {
        const updatedPermissions = [...selectedPermissions];
        const permissionIndex = updatedPermissions.findIndex(permission => permission.menu_name === menuName);
        if (permissionIndex !== -1) {
            updatedPermissions[permissionIndex][permissionType] = !updatedPermissions[permissionIndex][permissionType];
            setSelectedPermissions(updatedPermissions);
        }
    };

    return (
        <>
            <JqueryLoad />
            <Hdashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="index.php" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>

                    <div className="class-content">
                        <span className="arrow_left d-block" onClick={handleBack}>
                            <span>
                                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313"></path>
                                </svg>
                                <span className="ms-2">Back</span>
                            </span>
                        </span>

                        <section className="requirement_form">
                            <h2 className="mb-4 fw-600 poppins text-capitalize text-center text-purple">Edit Sub Admin</h2>

                            <div className="row align-items-center justify-content-center">
                                <div className="col-lg-9">
                                    <div className="card border-purple p-4">
                                        <form className="custom_form " id="" name="subAdminForm" onSubmit={subAdminForm.handleSubmit}>

                                            <div className="text-center">
                                                <span onClick={uploadImage}>
                                                    <img
                                                        src={image === null ? "./assets/images/user1.jpg" : image}
                                                        alt=""
                                                        className="img img-responsive"
                                                        style={{ borderRadius: "50%", height: "100px", width: "100px" }}
                                                    />
                                                </span>
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    ref={fileRef}
                                                    className="form-control d-none"
                                                    name="profile_image"
                                                    onChange={uploadImageEvent}
                                                />
                                                <center><label className="fw-600 text-dark">Profile Picture</label></center>
                                                {displayError("profile_image") && (
                                                    <div className="text-danger">{subAdminForm.errors.profile_image}</div>
                                                )}
                                            </div>

                                            <div className="form-group mb-4">
                                                <label className="mb-2 fw-600 text-dark">Name</label>
                                                <input className="form-control" type="text" name="name" value={subAdminForm.values.name} onChange={subAdminForm.handleChange} />
                                                {displayError("name") && (
                                                    <div className="text-danger">{subAdminForm.errors.name}</div>
                                                )}
                                            </div>

                                            <div className="form-group mb-4 ">
                                                <label className="mb-2 text-dark fw-600">Email</label>
                                                <input type="email" className="form-control" name="email" value={subAdminForm.values.email} onChange={subAdminForm.handleChange} />
                                                {displayError("email") && (
                                                    <div className="text-danger">{subAdminForm.errors.email}</div>
                                                )}
                                            </div>

                                            <div className="form-group mb-4">
                                                <label className="mb-4 fw-600 text-dark">Select Permission</label><br />

                                                {menu.map((menuItem, index) => (
                                                    <div key={index} className="col-md-12 mb-4">
                                                        <div className="row align-items-center">
                                                            <div className="col-md-2">
                                                                <div className="d-flex align-items-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`permission-${index}`}
                                                                        name={`add_permission`}
                                                                        checked={
                                                                            selectedPermissions.some(permission =>
                                                                                permission.menu_name === menuItem.menu_name &&
                                                                                (permission.add || permission.edit || permission.delete || permission.view || permission.status)
                                                                            )
                                                                        }
                                                                        value={menuItem.menu_name}
                                                                        onChange={(e) => handlePermissionClick(menuItem, index, e)}
                                                                    />
                                                                    <label htmlFor={`permission-${index}`} className="mx-2 fw-500" >
                                                                        {menuItem.menu_name}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            {/* Add */}
                                                            {menuItem.add === 1 ? (
                                                                <div className="col-md-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`add-${index}`}
                                                                        name={`add-${index}`}
                                                                        value="add"
                                                                        checked={selectedPermissions.find(permission => permission.menu_name === menuItem.menu_name)?.add}
                                                                        onChange={() => handleChildPermissionClick(menuItem.menu_name, 'add')}
                                                                    />
                                                                    <label htmlFor={`add-${index}`} className="mx-2">Add</label><br />
                                                                </div>
                                                            ) : <div className="col-md-2">
                                                                <label className="mx-2 text-danger">Add not available</label><br />
                                                            </div>}
                                                            {/* Edit */}
                                                            {menuItem.edit === 1 ? (
                                                                <div className="col-md-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`edit-${index}`}
                                                                        name={`edit-${index}`}
                                                                        value="edit"
                                                                        checked={selectedPermissions.find(permission => permission.menu_name === menuItem.menu_name)?.edit}
                                                                        onChange={() => handleChildPermissionClick(menuItem.menu_name, 'edit')}
                                                                    />
                                                                    <label htmlFor={`edit-${index}`} className="mx-2">Edit</label><br />
                                                                </div>
                                                            ) : <div className="col-md-2">
                                                                <label className="mx-2 text-danger" >Edit not available</label><br />
                                                            </div>}
                                                            {/* Delete */}
                                                            {menuItem.delete === 1 ? (
                                                                <div className="col-md-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`delete-${index}`}
                                                                        name={`delete-${index}`}
                                                                        value="delete"
                                                                        checked={selectedPermissions.find(permission => permission.menu_name === menuItem.menu_name)?.delete}
                                                                        onChange={() => handleChildPermissionClick(menuItem.menu_name, 'delete')}
                                                                    />
                                                                    <label htmlFor={`delete-${index}`} className="mx-2">Delete</label><br />
                                                                </div>
                                                            ) : <div className="col-md-2">
                                                                <label className="mx-2 text-danger" >Delete not available</label><br />
                                                            </div>}
                                                            {/* View */}
                                                            {menuItem.view === 1 ? (
                                                                <div className="col-md-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`view-${index}`}
                                                                        name={`view-${index}`}
                                                                        value="view"
                                                                        checked={selectedPermissions.find(permission => permission.menu_name === menuItem.menu_name)?.view}
                                                                        onChange={() => handleChildPermissionClick(menuItem.menu_name, 'view')}
                                                                    />
                                                                    <label htmlFor={`view-${index}`} className="mx-2">View</label><br />
                                                                </div>
                                                            ) : <div className="col-md-2">
                                                                <label className="mx-2 text-danger" >View not available</label><br />
                                                            </div>}
                                                            {/* Status */}
                                                            {menuItem.status === 1 ? (
                                                                <div className="col-md-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`status-${index}`}
                                                                        name={`status-${index}`}
                                                                        value="status"
                                                                        checked={selectedPermissions.find(permission => permission.menu_name === menuItem.menu_name)?.status}
                                                                        onChange={() => handleChildPermissionClick(menuItem.menu_name, 'status')}
                                                                    />
                                                                    <label htmlFor={`status-${index}`} className="mx-2">Block unblock</label><br />
                                                                </div>
                                                            ) : <div className="col-md-2">
                                                                <label className="mx-2 text-danger" >Blovk unblock not available</label><br />
                                                            </div>}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className="button">
                                                <button type="submit" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </main>
        </>
    );
}


