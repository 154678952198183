import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { AccessAccount, get_class_details } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import FontAwesome from "react-fontawesome";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import customStyles from "../../../utils/dataTableStyles";
import { encryptString } from "../../../api/apiClient";
import { TOAST_ERROR } from "../../../utils/common.service";

export default function NewLeadsClassDetails({ isLoader }) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [search, setSearch] = useState('')
    const location = useLocation()
    const userId = location.state && location.state.user_id
    const teacherId = location.state && location.state.teacher_id

    const handleGetData = () => {
        isLoader(true)
        get_class_details({ search: search, user_id: userId, teacher_id: teacherId }).then((response) => {
            console.log('response :', response);
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setData(dataWithRowCount);
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
            isLoader(false)
        })
        isLoader(false)
    }

    useEffect(() => {
        handleGetData();
    }, [search])

    const handleRedirect = (id, class_id, category, activity_type, type) => {
        isLoader(true);
        AccessAccount({ user_id: id })
            .then((response) => {
                if (response.code === "1") {

                    localStorage.setItem("Ttoken", response.data[0]?.token);
                    localStorage.setItem("TisLogin", true);
                    localStorage.setItem("type", category);
                    localStorage.setItem("Tid", id);
                    localStorage.setItem("role", "admin");
                    isLoader(false);

                    // Create the string to be encrypted
                    let queryString = `${class_id}?type=${activity_type.activity_type}`;
                    if (activity_type.activity_type === "class") {
                        queryString += `&type_class=${type}`;
                    }

                    // Encrypt the full query string
                    const encryptedURL = encryptString(queryString);

                    // Use the encrypted URL in the redirection
                    let url;
                    if (activity_type.activity_type === "class" || activity_type.activity_type === "club" || activity_type.activity_type === "competition") {
                        url = `https://studentscholars.com/teach-detail/${encryptedURL}`;
                    } else if (activity_type.activity_type === "quiz") {
                        url = `https://studentscholars.com/view-details-quiz/${encryptedURL}`;
                    }

                    window.open(url, '_blank');

                } else {
                    isLoader(false);
                    TOAST_ERROR("ERROR..!!");
                }
            })
            .catch((error) => {
                TOAST_ERROR("ERROR..!!");
                isLoader(false);
            });
    };

    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "80px"
        },
        {
            name: 'Learner Name',
            selector: row => row.learner_name,
            sortable: true,
            width: "170px"
        },
        {
            name: 'Teacher Name',
            selector: row => row.teacher_name,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Activity Type',
            selector: row => row.activity_type,
            sortable: true,
            width: "160px"
        },
        {
            name: 'Activity Title',
            selector: row => row.title,
            sortable: true,
            width: "370px"
        },
        {
            name: 'Main Category',
            selector: row => row.main_category,
            sortable: true,
            width: "180px"
        },
        {
            name: 'Subject Category',
            selector: row => row.subject_category,
            sortable: true,
            width: "180px"
        },
        {
            name: 'URL of Classroom',
            cell: (row) => (
                <button style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                    onClick={() => handleRedirect(row.teacher_id, row.id, row.category, { activity_type: "class" }, row.type)}
                >Click Here</button>
            ),
            sortable: true,
            width: "150px"
        },
    ]

    const handleBack = () => {
        navigate(-1);
    }

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>
                <span onClick={handleBack} className="mx-3 my-3">
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                    </svg>
                    <span className="ms-2">Back</span>
                </span>
                <>
                    <div className="fs-3 fw-500 ms-4">
                        Class Details
                    </div>
                    <div className="px-5">
                        <div className="d-flex justify-content-end align-items-center search-container">
                            <div className="row">
                                <div className="col">
                                    <CInputGroup>
                                        <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                        <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                            <FontAwesome className="fa-solid fa-search" name="search" />
                                        </CButton>
                                    </CInputGroup>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2">
                            {(data && data.length > 0 ? (
                                <DataTable columns={columns} data={data} pagination customStyles={customStyles} />
                            ) : (
                                <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No Data Found</p>
                            )
                            )}
                        </div>
                    </div>
                </>
            </div>
        </main>
    </>
}