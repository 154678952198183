import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import { admin_permission_detail, organizationTeacherDetails, userBlockUnblock, userDelete } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import FontAwesome from "react-fontawesome";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import Swal from "sweetalert2";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import SwalOld from "sweetalert"
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import customStyles from "../../../utils/dataTableStyles";

export default function OrgTeacherData({isLoader}) {

    const [data, setData] = useState([])
    const [activeActionPending, setActiveActionPending] = useState(false);
    const navigate = useNavigate()
    const location = useLocation()
    const OrganizationId = location.state && location.state.orgId
    const AdminId = localStorage.getItem("aID")
    const [permissions, setPermission] = useState([]);
    const role = localStorage.getItem("adminRole");
    const [search, setSearch] = useState('')

    const OrgTeacherDetails = () => {
        isLoader(true)
        organizationTeacherDetails({ id: OrganizationId , search : search}).then((response) => {
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setData(dataWithRowCount);
                isLoader(false)
            }else{
                setData([])
                isLoader(false)
            }
        });
    }

    const handleBack = () => {
        navigate(-1);
    };

    const handleDelete = async (id) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete?",
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    isLoader(true)
                    userDelete({ id: id }).then((response) => {
                        if (response.code == 1) {
                            setData((prevData) => prevData.filter((user) => user.id !== id))
                            TOAST_SUCCESS(response.message);
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    })
                } catch (error) {
                    TOAST_ERROR("Error");
                    isLoader(false)
                }
            }
        });
    };

    const handleView = (id) => {
        navigate('/org_teacher_details', { state: { userId: id } })
    }

    const clickActive = (id, is_active , full_name , email) => {
        const actionText = is_active === 1 ? 'block' : 'unblock';
        setActiveActionPending(true);
        SwalOld({
            title: 'Are you sure?',
            text: 'You want to ' + actionText + ' this teacher ?',
            icon: 'warning',
            buttons: {
                confirm: {
                    text: is_active === 1 ? 'Block' : 'Unblock',
                    className: 'btn-success'
                },
                cancel: 'Cancel',
            },
            dangerMode: true,
        })
            .then((activate) => {
                setActiveActionPending(false);
                if (activate) {
                    if (is_active === 1) {
                        HandleMakeInactive(id , full_name , email);
                    } else {
                        HandleMakeActive(id , full_name , email);
                    }
                } else {
                    OrgTeacherDetails();
                }
            });
    }

    const HandleMakeActive = async (id , full_name , email) => {
        let catid = `${id}`
        let objCategory = {
            id: catid,
            is_active: "1",
            name : full_name ,
            email : email,
        };
        isLoader(true)
        userBlockUnblock((objCategory)).then((response) => {
            if (response.code == "1") {
                updateLearnerStatus(id, 1);
                TOAST_SUCCESS(response.message);
                OrgTeacherDetails();
                isLoader(false)
            } else {
                TOAST_ERROR(response.message);
                isLoader(false)
            }
        })
    };

    const HandleMakeInactive = async (id , full_name , email) => {
        let catid = `${id}`
        let objCategory = {
            id: catid,
            is_active: "0",
            name : full_name,
            email : email
        };
        isLoader(true)
        userBlockUnblock((objCategory)).then((response) => {
            if (response.code == "1") {
                updateLearnerStatus(id, 0);
                TOAST_SUCCESS(response.message);
                OrgTeacherDetails();
                isLoader(false)
            } else {
                TOAST_ERROR(response.message);
                isLoader(false)
            }
        })
    };

    const updateLearnerStatus = (id, status) => {
        setData(prevData =>
            prevData.map(learner =>
                learner.id === id ? { ...learner, is_active: status } : learner
            )
        );
    };

    const subAdminPermission = () => {
        admin_permission_detail({ id: AdminId })
            .then((response) => {
                if (response.code == 1) {
                    setPermission(response.data);
                } else {
                    setPermission([]);
                }
            })
            .catch((error) => {
                setPermission([]);
            });
    }

    useEffect(() => {
        OrgTeacherDetails();
        if (role !== "S") {
            subAdminPermission();
        }
    }, [search])

    const handleView2 = (id) => {
        navigate('/org_teacher_teaching_class_details', { state: { teacher_id: id } });
    }

    const organizationPermission = permissions.find(permission => permission.menu_name === "Organization");

    // Check if status,deleting is allowed for "Organization"
    const isDeleteAllowed = organizationPermission && organizationPermission.delete === 1;
    const isStatusAllowed = organizationPermission && organizationPermission.status === 1;

    const getStatus = (step) => {
        switch(step) {
            case 0:
                return <button className="bg-warning border-0" style={{width : "70px"}}>Pending</button>;
            case 1:
                return <button className="bg-warning border-0" style={{width : "70px"}}>Pending</button>;
            case 2:
                return <button className="bg-success border-0 text-light" style={{width : "70px"}}>Accepted</button>;
            case 3:
                return <button className="bg-danger text-light border-0" style={{width : "70px"}}>Rejected</button>;
            default:
                return "";
        }
    }; 

    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "70px"
        },
        {
            name: (
                <>
                    Registered<br />Date
                </>
            ),
            selector: row => {
                const date = new Date(row.created_at);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const formattedMonth = month < 10 ? `0${month}` : month;
                const formattedDay = day < 10 ? `0${day}` : day;
                const year = date.getFullYear();
                return `${formattedDay}-${formattedMonth}-${year}`;
            },
            sortable: true,
            width: "120px"
        },
        {
            name: (
                <>
                    Id<br />Code
                </>
            ),
            selector: row => row.code_generate,
            sortable: true,
            width: "100px",
            cell: row => row.code_generate == null ? "0000" : row.code_generate
        },
        {
            name: 'Image',
            cell: (data) => (
                <img
                    src={data.profile_image}
                    style={{ width: '50px', height: '50px'}}
                />
            ),
            sortable: true,
            width: "90px"
        },
        {
            name: 'Full Name',
            selector: row => row.full_name,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "250px"
        },
        {
            name: 'Teach',
            cell: (row) => (
                <button style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }} onClick={() => handleView2(row.id)}>View Class</button>
            ),
            sortable: true,
            width: "120px"
        },
        {
            name: (
                <>
                    Background<br />Check
                </>
            ),
            cell: (row) => getStatus(row.step_1),
            sortable: true,
            width: "130px"
        },    
        {
            name: (
                <>
                    Id<br />Verification
                </>
            ),
            cell: (row) => getStatus(row.step_2),
            sortable: true,
            width: "130px"
        }, 
        {
            name: 'Actions',
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    {(role === "S" || isDeleteAllowed) &&
                        <div className="col me-2">
                            <FontAwesome
                            name="delete"
                                className="fs-5 fa-solid fa-trash  mx-2 my-2 bg-light text-danger"
                                onClick={() => handleDelete(row.id)}
                            ></FontAwesome>
                        </div>
                    }
                    <div className="col me-2">
                        <FontAwesome
                        name="view"
                            className="fs-5 fa-solid fa-eye  mx-2 my-2 bg-light text-secondary"
                            onClick={() => handleView(row.id)}
                        ></FontAwesome>
                    </div>
                </div>
            ),
        },
        {
            name: role == "S" || isStatusAllowed ? 'Block/UnBlock' : '',
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    {(role === "S" || isStatusAllowed) && (
                        <BootstrapSwitchButton
                            checked={row.is_active === 1}
                            onlabel='Block'
                            offlabel='UnBlock'
                            width={90}
                            size="sm"
                            offstyle="light text-dark"
                            onChange={() => {
                                { clickActive(row.id, row.is_active , row.full_name , row.email) }
                            }}
                        />
                    )}
                </div>
            )
        }
    ]

    return <>
        <JqueryLoad />

        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">

                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>

                <span onClick={handleBack} className="mx-4 my-3 mt-4">
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                    </svg>

                    <span className="ms-2">Back</span>
                </span>
                <>
                    <div className="fs-3 fw-500 ms-4 mb-2">
                        Organization Teacher Data
                    </div>
                    <div className="d-flex justify-content-end align-items-center mb-3 search-container">
                        <div className="row me-5">
                            <div className="col">
                                <CInputGroup>
                                    <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                    <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                        <FontAwesome className="fa-solid fa-search" name="search"/>
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>
                    </div>

                    <div className="px-5 mt-1">
                        {data && data.length > 0 ? (
                            <>
                                <DataTable columns={columns} data={data} pagination customStyles={customStyles}/>
                            </>
                        ) : (
                            <>
                                <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No data Found</p>
                            </>
                        )}
                    </div>
                </>
            </div>
        </main>
    </>
}