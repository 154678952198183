import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import { admin_permission_detail, block_unblock_subject, delete_subject, get_subject } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import FontAwesome from "react-fontawesome";
import Swal from "sweetalert2";
import SwalOld from "sweetalert"
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import customStyles from "../../../utils/dataTableStyles";

export default function Subject({isLoader}) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const AdminId = localStorage.getItem("aID")
    const [permissions, setPermission] = useState([]);
    const role = localStorage.getItem("adminRole");
    const [search, setSearch] = useState('')
    const [activeActionPending, setActiveActionPending] = useState(false);

    const handleGetData = () => {
        isLoader(true)
        get_subject({ search: search }).then((response) => {
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setData(dataWithRowCount);
                isLoader(false)
            }else{
                setData([])
                isLoader(false)
            }
            isLoader(false)
        })
        isLoader(false)
    }

    const handlesubjectDetails = (categoryName, mainCategoryName, Image, subjectName) => {
        navigate('/subject-details', { state: { categoryName: categoryName, mainCategoryName: mainCategoryName, Image: Image, subjectName: subjectName } })
    }

    const handleEditsubject = (subjectId) => {
        navigate('/edit-subject', { state: { subjectId: subjectId } })
    }

    const handleDeletesubject = async (subjectId) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete?",
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    isLoader(true)
                    const response = await delete_subject({ id: subjectId });
                    if (response.code === '1') {
                        TOAST_SUCCESS(response.message);
                        handleGetData();
                        isLoader(false)
                    } else {
                        TOAST_ERROR(response.message);
                        isLoader(false)
                    }
                } catch (error) {
                    TOAST_ERROR("Error deleting category");
                    isLoader(false)
                }
            }
        });
    };

    const clickActive = (id, is_active) => {
        const actionText = is_active === 1 ? 'deactivate' : 'activate';
        setActiveActionPending(true)
        SwalOld({
            title: 'Are you sure?',
            text: 'Do you want to ' + actionText + ' this subject ?',
            icon: 'warning',
            buttons: {
                confirm: {
                    text: is_active === 1 ? 'Deactivate' : 'Activate',
                    className: 'btn-success'
                },
                cancel: 'Cancel',
            },
            dangerMode: true,
        })
            .then((activate) => {
                setActiveActionPending(false)
                if (activate) {
                    if (is_active === 1) {
                        HandleMakeInactive(id);
                    } else {
                        HandleMakeActive(id);
                    }
                } else {
                    handleGetData();
                }
            });
    }

    const HandleMakeActive = async (id) => {
        let catid = `${id}`
        let objCategory = {
            id: catid,
            is_active: "1"
        };
        isLoader(true)
        block_unblock_subject((objCategory)).then((response) => {
            if (response.code == "1") {
                updateLearnerStatus(id, 1);
                TOAST_SUCCESS(response.message);
                handleGetData();
                isLoader(false)
            } else {
                TOAST_ERROR(response.message);
                isLoader(false)
            }
        })
    };

    const HandleMakeInactive = async (id) => {
        let catid = `${id}`
        let objCategory = {
            id: catid,
            is_active: "0"
        };
        isLoader(true)
        block_unblock_subject((objCategory)).then((response) => {
            if (response.code == "1") {
                updateLearnerStatus(id, 0);
                TOAST_SUCCESS(response.message);
                handleGetData();
                isLoader(false)
            } else {
                TOAST_ERROR(response.message);
                isLoader(false)
            }
        })
    };

    const subAdminPermission = () => {
        admin_permission_detail({ id: AdminId })
            .then((response) => {
                if (response.code == 1) {
                    setPermission(response.data);
                } else {
                    setPermission([]);
                }
            })
            .catch((error) => {
                setPermission([]);
            });
    }

    useEffect(() => {
        handleGetData()
        if (role !== "S") {
            subAdminPermission();
        }
    }, [search])

    const updateLearnerStatus = (id, status) => {
        setData(prevData =>
            prevData.map(learner =>
                learner.id === id ? { ...learner, is_active: status } : learner
            )
        );
    };

    const subjectPermissions = permissions.find(permission => permission.menu_name === "Subjects");

    // Check if add,edit,delete,status is allowed for "Subjects"
    const isAddAllowed = subjectPermissions && subjectPermissions.add === 1;
    const isEditAllowed = subjectPermissions && subjectPermissions.edit === 1;
    const isDeleteAllowed = subjectPermissions && subjectPermissions.delete === 1;
    const isStatusAllowed = subjectPermissions && subjectPermissions.status === 1;

    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "100px"
          },
        {
            name: 'Category Name',
            selector: row => row.category_name,
            sortable: true,
            width: "300px"
        },
        {
            name: 'Subject Name',
            selector: row => row.subject_name,
            sortable: true,
            width: "400px"
        },
        {
            name: 'Image',
            cell: (data) => (
                <img
                    src={data.subject_category_image}
                    style={{ width: '50px', height: '50px'}}
                />
            ),
            sortable: true,
            width: "200px"
        },
        {
            name: 'Actions',
            width: "300px",
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    {(role === "S" || isEditAllowed) &&
                        <div className="col me-2">
                            <div>
                                <FontAwesome
                                    className="fs-5 fa-solid fa-edit mx-2 my-2 text-info"
                                    name="edit"
                                    onClick={() => handleEditsubject(row.id, row.main_subject_image, row.subject_name)}
                                ></FontAwesome>
                            </div>
                        </div>
                    }
                    {(role === "S" || isDeleteAllowed) &&
                        <div className="col me-2">
                            <FontAwesome
                            name="delete"
                                className="fs-5 fa-solid fa-trash  mx-2 my-2 bg-light text-danger"
                                onClick={() => handleDeletesubject(row.id)}
                            ></FontAwesome>
                        </div>
                    }
                    <div className="col me-2">
                        <FontAwesome
                        name="view"
                            className="fs-5 fa-solid fa-eye  mx-2 my-2 bg-light text-secondary"
                            onClick={() => handlesubjectDetails(row.category_name, row.main_category, row.subject_category_image, row.subject_name)}
                        ></FontAwesome>
                    </div>
                </div>
            ),
        },
        {
            name: role == "S" || isStatusAllowed ? 'Active/Inactive' : '',
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    {(role === "S" || isStatusAllowed) && (
                        <BootstrapSwitchButton
                            checked={row.is_active === 1 ? true : false}
                            onlabel='InActive'
                            offlabel='Active'
                            width={90}
                            size="sm"
                            onChange={() => {
                                { clickActive(row.id, row.is_active) }
                            }}
                        />
                    )}
                </div>
            ),
        },
    ]

    const addData = () => {
        navigate('/add-subject')
    }

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./logo.png" alt="logo" />
                            </a>
                        </div>

                    </div>
                </header>
                <>
                    <div className="fs-3 fw-500 mt-4 ms-4 mb-1">
                        Subject Data
                    </div>
                    <div className="px-5 mt-1">
                        <div className="d-flex justify-content-end align-items-center mb-3 search-container">
                            <div className="row me-2">
                                <div className="col">
                                    <CInputGroup>
                                        <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                        <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                            <FontAwesome className="fa-solid fa-search" name="search" />
                                        </CButton>
                                    </CInputGroup>
                                </div>
                            </div>
                            {(role === "S" || isAddAllowed) &&
                                <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" onClick={addData}>
                                    + Add Subject
                                </button>
                            }
                        </div>
                        <div>
                            {(data && data.length > 0 ? (
                                    <DataTable columns={columns} data={data} pagination customStyles={customStyles}/>
                                ) : (
                                    <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No data Found</p>
                                )
                            )}
                        </div>
                    </div>
                </>
            </div>
        </main>
    </>
}