import { axiosClient } from "./apiClient";

// Login

export function loginApi(data) {
  return axiosClient.post("/v2/admin/login", data);
}

// Dashboard

export function dashboardCountApi(data) {
  return axiosClient.post("/v2/admin/dashboard", data);
}

// export function latest_news(data) {
//   return axiosClient.post("/v1/student_parent/latest_news", data);
// }

// Student parents

export function studentParentList(data) {
  return axiosClient.post("/v2/admin/student_parent_list", data);
}

export function parentLearnerList(data) {
  return axiosClient.post("/v2/admin/parent_learner_list", data);
}

export function classList(data) {
  return axiosClient.post("/v2/admin/class_list", data);
}

export function classLearnerList(data) {
  return axiosClient.post("/v2/admin/class_learner_list", data);
}

export function LearnerSubscriptionclassList(data) {
  return axiosClient.post("/v2/admin/subscription_class_list_of_learners", data);
}

export function SubscriptionclassList(data) {
  return axiosClient.post("/v2/admin/subscription_class_list", data);
}

export function userDelete(data) {
  return axiosClient.post("/v2/admin/user_delete", data);
}

export function learnerDelete(data) {
  return axiosClient.post("/v2/admin/learner_delete", data);
}

export function learnerDetails(data) {
  return axiosClient.post("/v2/admin/learner_detail", data);
}

export function userDetails(data) {
  return axiosClient.post("/v2/admin/user_detail", data);
}

export function userBlockUnblock(data) {
  return axiosClient.post("/v2/admin/user_block_unblock", data);
}

export function learnerBlockUnblock(data) {
  return axiosClient.post("/v2/admin/learner_block_unblock", data);
}

// Teacher

export function teacherList(data) {
  return axiosClient.post("/v2/admin/individual_teacher_list", data);
}

export function teacherApplications(data) {
  return axiosClient.post("/v2/admin/teacher_step_1_details", data);
}

export function teacherIdentity(data) {
  return axiosClient.post("/v2/admin/teacher_step_2_details", data);
}

export function teacherBackground(data) {
  return axiosClient.post("/v2/admin/teacher_step_3_details", data);
}

export function teacherStepAccept(data) {
  return axiosClient.post("/v2/admin/teacher_step_accept", data);
}

export function sendComment(data) {
  return axiosClient.post("/v2/admin/admin_to_reply_teacher_org", data);
}

export function Profile_Teacher_Org(data) {
  return axiosClient.post("/v2/admin/about_steps_org_teacher", data);
}

// Organization

export function organizationData(data) {
  return axiosClient.post("/v2/admin/organization_list", data);
}

export function organizationTeacherDetails(data) {
  return axiosClient.post("/v2/admin/organization_teacher_list", data);
}

export function orgApplications(data) {
  return axiosClient.post("/v2/admin/org_step_1_details", data);
}

export function orgIdentity(data) {
  return axiosClient.post("/v2/admin/org_step_2_details", data);
}

export function orgAgreement(data) {
  return axiosClient.post("/v2/admin/org_step_3_details", data);
}

export function orgAuthorization(data) {
  return axiosClient.post("/v2/admin/org_step_4_details", data);
}

export function organizationStepAccept(data) {
  return axiosClient.post("/v2/admin/org_steps_accpets", data);
}

export function orgTeacherClassList(data) {
  return axiosClient.post("/v2/admin/org_teacher_assign_class_list", data);
}



// Category 

export function get_category(data) {
  return axiosClient.post("/v2/admin/main_category_list", data);
}

export function add_category(data) {
  return axiosClient.post("/v2/admin/category_add", data);
}

export function update_category(data) {
  return axiosClient.post("/v2/admin/category_update", data);
}

export function delete_category(data) {
  return axiosClient.post("/v2/admin/category_delete", data);
}

export function block_unblock_category(data) {
  return axiosClient.post("/v2/admin/category_block_unblock", data);
}

//subject
export function get_subject(data) {
  return axiosClient.post("/v2/admin/subject_category_list", data);
}

export function get_sub_category(data) {
  return axiosClient.post("/v2/admin/category_list", data);
}

export function add_subject(data) {
  return axiosClient.post("/v2/admin/subject_category_add", data);
}

export function get_single_subject(data) {
  return axiosClient.post("/v2/admin/subject_category_details", data);
}

export function update_subject(data) {
  return axiosClient.post("/v2/admin/subject_category_update", data);
}

export function delete_subject(data) {
  return axiosClient.post("/v2/admin/subject_category_delete", data);
}

export function block_unblock_subject(data) {
  return axiosClient.post("/v2/admin/subject_category_block_unblock", data);
}

//blog

export function get_blog(data) {
  return axiosClient.post("/v2/admin/blog_list", data);
}

export function add_blog(data) {
  return axiosClient.post("/v2/admin/blog_add", data);
}

export function update_blog(data) {
  return axiosClient.post("/v2/admin/blog_update", data);
}

export function delete_blog(data) {
  return axiosClient.post("/v2/admin/blog_delete", data);
}

export function get_blog_details(data) {
  return axiosClient.post("/v2/admin/blog_details", data);
}

export function delete_blog_media(data) {
  return axiosClient.post("/v2/admin/remove_blogs_images", data);
}

//Faq

export function get_faq(data) {
  return axiosClient.post("/v2/admin/faq_list", data);
}

export function add_faq(data) {
  return axiosClient.post("/v2/admin/faq_add", data);
}

export function update_faq(data) {
  return axiosClient.post("/v2/admin/faq_update", data);
}

export function delete_faq(data) {
  return axiosClient.post("/v2/admin/faq_delete", data);
}

//Faq Training Videos

export function get_faq_video(data) {
  return axiosClient.post("/v2/admin/faq_training_video_list", data);
}

export function add_faq_video(data) {
  return axiosClient.post("/v2/admin/faq_training_video_add", data);
}

export function update_faq_video(data) {
  return axiosClient.post("/v2/admin/faq_training_video_update", data);
}

export function delete_faq_video(data) {
  return axiosClient.post("/v2/admin/faq_training_video_delete", data);
}


// Role Base Access

export function subAdminList(data) {
  return axiosClient.post("/v2/admin/sub_admin_list", data);
}

export function subAdminDelete(data) {
  return axiosClient.post("/v2/admin/admin_delete", data);
}

export function menuList(data) {
  return axiosClient.post("/v2/admin/menu_list", data);
}

export function add_sub_admin(data) {
  return axiosClient.post("/v2/admin/add_sub_admin", data);
}

export function update_sub_admin(data) {
  return axiosClient.post("/v2/admin/update_sub_admin", data);
}

export function add_permission(data) {
  return axiosClient.post("/v2/admin/add_permission", data);
}

export function admin_detail(data) {
  return axiosClient.post("/v2/admin/admin_detail", data);
}

export function admin_permission_detail(data) {
  return axiosClient.post("/v2/admin/admin_permission_detail", data);
}

export function update_admin_permission(data) {
  return axiosClient.post("/v2/admin/update_admin_permission", data);
}

// Settings

export function logout(data) {
  return axiosClient.post("/v2/admin/log_out", data);
}

export function change_password(data) {
  return axiosClient.post("/v2/admin/change_password", data);
}

export function update_commission(data) {
  return axiosClient.post("/v2/admin/commission_update", data);
}

// Pages

export function update_cms_pages(data) {
  return axiosClient.post("/v2/admin/update_pages", data);
}

export function get_cms_pages(data) {
  return axiosClient.post("/v2/admin/get_pages", data);
}

export function get_contactus_list(data) {
  return axiosClient.post("/v2/admin/contact_us", data);
}

export function get_contactus_details(data) {
  return axiosClient.post("/v2/admin/contact_us_detail", data);
}

export function contact_us_reply(data) {
  return axiosClient.post("/v2/admin/reply_contact_us", data);
}

//Seo User

export function seo_list(data) {
  return axiosClient.post("/v2/admin/seo_list", data);
}

export function seo_tag_add(data) {
  return axiosClient.post("/v2/admin/seo_add", data);
}

export function seo_tag_update(data) {
  return axiosClient.post("/v2/admin/seo_update", data);
}

export function seo_tag_delete(data) {
  return axiosClient.post("/v2/admin/seo_delete", data);
}

//Admin Earnings

export function admin_earning(data) {
  return axiosClient.post("/v2/admin/admin_earnings", data);
}


//Send Notification

export function fetch_user_data(data) {
  return axiosClient.post("/v2/admin/notification_user_listing", data);
}

export function send_notification(data) {
  return axiosClient.post("/v2/admin/send_push_notification", data);
}

export function notificationListing(data) {
  return axiosClient.post("/v2/admin/notification_listing", data);
}

export function notification_count(data) {
  return axiosClient.post("/v2/admin/notification_count", data);
}


// Reports 

export function AccessAccount(data) {
  return axiosClient.post("/v2/admin/access_account", data);
}

export function Top_10_Teachers(data) {
  return axiosClient.post("/v2/admin/get_top_10_teachers", data);
}

export function Top_10_Organization(data) {
  return axiosClient.post("/v2/admin/get_top_10_organization", data);
}

export function Payments_to_Teacher_Org(data) {
  return axiosClient.post("/v2/admin/payments_made_to_teacher_org", data);
}

export function Total_Review_Received(data) {
  return axiosClient.post("/v2/admin/reviews_received", data);
}

export function Country_Teacher_Join(data) {
  return axiosClient.post("/v2/admin/list_of_countries_and_teachers_joined", data);
}

export function Country_Student_Join(data) {
  return axiosClient.post("/v2/admin/list_of_countries_and_students_joined", data);
}

export function Subject_Enrolled(data) {
  return axiosClient.post("/v2/admin/subcribed_category_listing", data);
}

export function Most_Complaint(data) {
  return axiosClient.post("/v2/admin/most_complaint_listing", data);
}

export function Complaint_Details(data) {
  return axiosClient.post("/v2/admin/complaint_details_of_teacher_org_org_teacher", data);
}

export function Top_25_Popular_Course(data) {
  return axiosClient.post("/v2/admin/most_popular_course_listing", data);
}

// Classroom 

export function classroomDetails(data) {
  return axiosClient.post("/v2/admin/class_quiz_competition_club_listing", data);
}

export function classDetails(data) {
  return axiosClient.post("/v2/admin/class_quiz_competition_club_detail", data);
}

export function sendCommentClassroom(data) {
  return axiosClient.post("/v2/admin/comment_on_class_quiz_competition_club", data);
}

export function AcceptRejectClassroom(data) {
  return axiosClient.post("/v2/admin/accept_class_quiz_competition_club", data);
}


// News Letter

export function NewsletterList(data) {
  return axiosClient.post("/v2/admin/newsletter_email_listing", data);
}

export function deleteNewsLetter(data) {
  return axiosClient.post("/v2/admin/delete_newsleter_email", data);
}

export function addNewsLetter(data) {
  return axiosClient.post("/v2/admin/add_newsletter", data);
}

export function NewsLetterListing(data) {
  return axiosClient.post("/v2/admin/newsletter_listing", data);
}

export function delete_News_Letter(data) {
  return axiosClient.post("/v2/admin/delete_newsletter", data);
}

export function News_Letter_Details(data) {
  return axiosClient.post("/v2/admin/newsletter_details", data);
}

// EWallet

export function PaymentMadetoTeachers(data) {
  return axiosClient.post("/v2/admin/payment_paid_to_teachers", data);
}

export function RefundsListing(data) {
  return axiosClient.post("/v2/admin/refunds_listing", data);
}

export function paymentReceived(data) {
  return axiosClient.post("/v2/admin/Payments_received_and_subscription_payment", data);
}

export function refferalCredits(data) {
  return axiosClient.post("/v2/admin/credits_listing", data);
}

export function AllTransactions(data) {
  return axiosClient.post("/v2/admin/ewallet_transactions", data);
}

export function RefundsRequestListing(data) {
  return axiosClient.post("/v2/admin/refund_request_list", data);
}

export function checkBalance(data) {
  return axiosClient.post("/v2/admin/check_balance", data);
}

export function invoice_pdf(data) {
  return axiosClient.post("/v2/admin/teacher_receipt", data);
}

export function refundPayementToUser(data) {
  return axiosClient.post("/v2/admin/refund_payment_to_user", data);
}

export function releaseHoldPayment(data) {
  return axiosClient.post("/v2/admin/release_hold_payment", data);
}

export function GroupTransactions(data) {
  return axiosClient.post("/v2/admin/grouped_transaction_details", data);
}

// Coupons

export function CoupounList(data) {
  return axiosClient.post("/v2/admin/coupan_listing", data);
}

export function CoupounDetails(data) {
  return axiosClient.post("/v2/admin/coupan_detail", data);
}

// New Leads

export function new_leads(data) {
  return axiosClient.post("/v2/admin/new_leads", data);
}

export function non_preffered_teachers(data) {
  return axiosClient.post("/v2/admin/non_preferred_teachers", data);
}

export function preferred_teachers(data) {
console.log('data :', data);
  return axiosClient.post("/v2/admin/preferred_teachers", data);
}

export function New_Leads_details(data) {
  return axiosClient.post("/v2/admin/request_class_detail", data);
}

export function get_class_details(data) {
  return axiosClient.post("/v2/admin/new_leads_activity_details", data);
}

export function new_leads_class_statistics(data) {
  return axiosClient.post("/v2/admin/new_leads_class_statistics", data);
}

export function new_leads_class_statistics_combined(data) {
  return axiosClient.post("/v2/admin/new_leads_class_statistics_combined", data);
}

export function request_by_preferred_and_non_preferred(data) {
  return axiosClient.post("/v2/admin/request_by_preferred_and_non_preferred", data);
}

export function top_10_teachers_picked(data) {
  return axiosClient.post("/v2/admin/top_10_teachers_picked", data);
}

export function top_10_teachers_not_picked(data) {
  return axiosClient.post("/v2/admin/top_10_teachers_not_picked", data);
}

export function teacher_detail_who_accepted_new_leads(data) {
  return axiosClient.post("/v2/admin/teacher_detail_who_accepted_new_leads", data);
}

// Password

export function forgot_password(data) {
  return axiosClient.post("/v1/auth/forgot_password", data);
}

export function reset_password(data) {
  return axiosClient.post("/v1/auth/reset_password", data);
}
