import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { AccessAccount, SubscriptionclassList } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import FontAwesome from "react-fontawesome";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import customStyles from "../../../utils/dataTableStyles";
import { TOAST_ERROR } from "../../../utils/common.service";
import { encryptString } from "../../../api/apiClient";

export default function OrgClassList({ isLoader }) {

    const [classData, setClassData] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const orgId = location.state && location.state.org_id
    const [search, setSearch] = useState('');

    const OrgClassData = () => {
        isLoader(true)
        SubscriptionclassList({ teacher_id: orgId, search: search }).then((response) => {
            console.log('response :', response);
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setClassData(dataWithRowCount);
                isLoader(false)
            }
            else {
                setClassData([])
                isLoader(false)
            }
            isLoader(false)
        });
        isLoader(false)
    }

    const handleRedirect = (id, class_id, category, activity_type, type) => {
        isLoader(true);
        AccessAccount({ user_id: id })
            .then((response) => {
                if (response.code === "1") {

                    localStorage.setItem("Ttoken", response.data[0]?.token);
                    localStorage.setItem("TisLogin", true);
                    localStorage.setItem("type", category);
                    localStorage.setItem("Tid", id);
                    localStorage.setItem("role", "admin");
                    isLoader(false);

                    // Create the string to be encrypted
                    let queryString = `${class_id}?type=${activity_type}`;
                    if (activity_type === "class") {
                        queryString += `&type_class=${type}`;
                    }

                    // Encrypt the full query string
                    const encryptedURL = encryptString(queryString);

                    // Use the encrypted URL in the redirection
                    let url;
                    if (activity_type === "class" || activity_type === "club" || activity_type === "competition") {
                        url = `https://studentscholars.com/organization-tech-detail/${encryptedURL}`;
                    } else if (activity_type === "quiz") {
                        url = `https://studentscholars.com/organization-view-details-quiz/${encryptedURL}`;
                    }

                    window.open(url, '_blank');

                } else {
                    isLoader(false);
                    TOAST_ERROR("ERROR..!!");
                }
            })
            .catch((error) => {
                TOAST_ERROR("ERROR..!!");
                isLoader(false);
            });
    };


    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "80px"
        },
        {
            name: 'Type',
            selector: row => row.activity_type,
            sortable: true,
            width: "120px"
        },
        {
            name: 'Category',
            selector: row => row.main_category,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Name',
            selector: row => row.activity_title,
            sortable: true,
            width: "250px"
        },
        // {
        //     name: (
        //         <>
        //             Assigened<br />Teacher
        //         </>
        //     ),
        //     selector: row => row.assigned_teacher ? row.assigned_teacher : "-",
        //     sortable: true,
        //     width: "150px"
        // },
        {
            name: (
                <>
                    Number of learners<br />currently subscribed
                </>
            ),
            selector: row => row.total_learners,
            sortable: true,
            width: "200px"
        },
        {
            name: (
                <>
                    Number of learners<br />taken this class
                </>
            ),
            selector: row => row.total_enrolled_learners,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Created Date',
            selector: row => {
                const date = new Date(row.created_at);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const formattedMonth = month < 10 ? `0${month}` : month;
                const formattedDay = day < 10 ? `0${day}` : day;
                const year = date.getFullYear();
                return `${formattedDay}-${formattedMonth}-${year}`;
            },
            sortable: true,
            width: "160px"
        },
        {
            name: 'Deleted Date',
            selector: row => {
                if (!row.delete_date) {
                    return "-";
                }
                const date = new Date(row.delete_date);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const formattedMonth = month < 10 ? `0${month}` : month;
                const formattedDay = day < 10 ? `0${day}` : day;
                const year = date.getFullYear();
                return `${formattedDay}-${formattedMonth}-${year}`;
            },
            sortable: true,
            width: "180px"
        },
        {
            name: 'URL',
            cell: (row) => (
                <button style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                    onClick={() => handleRedirect(row.id, row.activity_id, row.category, row.activity_type, row.platform_type)}
                >Click Here</button>
            ),
            sortable: true,
            width: "90px"
        }
    ];

    useEffect(() => {
        OrgClassData()
    }, [search])

    const handleBack = () => {
        navigate(-1);
    };

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>

                <span onClick={handleBack} className="mx-4 my-3 mt-2">
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                    </svg>
                    <span className="ms-2">Back</span>
                </span>
                <>
                    <div className="fs-3 fw-500 ms-4 mb-1">
                        Organization Class Data
                    </div>
                    <div className="d-flex justify-content-end align-items-center mb-2 search-container">
                        <div className="row me-5">
                            <div className="col">
                                <CInputGroup>
                                    <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                    <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                        <FontAwesome className="fa-solid fa-search" name="search" />
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>
                    </div>
                    <div className="px-5 mt-1">
                        {classData && classData.length > 0 ? (
                            <>
                                <DataTable columns={columns} data={classData} pagination customStyles={customStyles} />
                            </>
                        ) : (
                            <>
                                <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No data Found</p>
                            </>
                        )}
                    </div>
                </>
            </div>
        </main>
    </>
}