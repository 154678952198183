import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { loginApi } from "../../api/apiHandler";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../utils/common.service";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginRedirectCallWithDataStore } from "../Common/RedirectPathMange";
import moment from "moment-timezone";

export default function Login() {

  const [selectedTimezone, setSelectedTimezone] = useState("");
  const navigate = useNavigate();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email()
      .matches(/^(?!.*@[^,]*,)/)
      .required("Email address is required"),
    password: yup.string().required("Password is required"),
    timezone: yup.string()

  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const schemaMobile = yup.object().shape({
    phone: yup
      .string()
      .required("Phone Number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits and contain only numbers"),
    password: yup.string().required("Password is required"),
    timezone: yup.string()
  });

  const editTempFormOption = { resolver: yupResolver(schemaMobile) };
  const {
    formState: { errors: errors1 },
  } = useForm(editTempFormOption);

  const onSubmit = (data) => {

    loginApi({ email: data.email, password: data.password, timezone: data.timezone }).then((resposnse) => {
      if (resposnse.code == 1) {
        TOAST_SUCCESS(resposnse.message);
        loginRedirectCallWithDataStore(resposnse.data);
        navigate("/dashboard");
      } else {
        TOAST_ERROR(resposnse.message);
      }
    });
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (localStorage.getItem('AToken')) {
      navigate('/dashboard')
    }
  })

  const timezones = moment.tz.names().map((tz) => ({
    value: tz,
    label: `${tz} (UTC ${moment.tz(tz).format("Z")})`,
  }));

  useEffect(() => {
    // Detect and set the user's timezone as the default value.
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimezone(userTimezone);
    setValue('timezone', userTimezone);
  }, []);

  return (
    <>
      <Helmet>
        <script>{`
                  $("document").ready(function() {
                      $(".tab-slider--body").hide();
                      $(".tab-slider--body:first").show();
                  });
                  $(".tab-slider--nav li").click(function() {
                      $(".tab-slider--body").hide();
                      var activeTab = $(this).attr("rel");
                      $("#" + activeTab).fadeIn();
                      if ($(this).attr("rel") == "tab2") {
                          $('.tab-slider--tabs').addClass('slide');
                      } else {
                          $('.tab-slider--tabs').removeClass('slide');
                      }
                      $(".tab-slider--nav li").removeClass("active");
                      $(this).addClass("active");
                  });
        `}</script>
      </Helmet>
      <div>
        <main>
          <div className="login_sec">
            <div className="container-fluid d-flex justify-content-center align-items-center">

              <div className="row d-flex justify-content-center align-items-center" style={{ width: "80vw", height: "90vh" }}>
                <div className="login-main-div col-lg-6 right" style={{ backgroundColor: "rgb(240, 240, 240)", boxShadow: "rgba(0, 0, 0, 0.20) 0px 5px 15px", borderRadius: "20px" }}>

                  <div className="btm_div form-section">
                    <div className="d-flex justify-content-center flex-column">
                      <p className="mb-2 mt-3 text-center poppins fs-2 fw-600 text-purple">Sign in to your Account</p>
                    </div>

                    <div className="tab-slider--container">
                      <div id="tab1" className="tab-slider--body">
                        <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4">
                          <div className="form-group mb-4">
                            <label className="mb-2">Email Address</label>
                            <input type="email" {...register("email")} name="email" className="form-control" />
                            <p className="mt-2 font-bold col-red">
                              <span style={{ color: "red" }}>{errors.email?.message}</span>
                            </p>
                          </div>
                          <div className="form-group mb-4">
                            <div className="d-flex justify-content-between position-relative">
                              <label className="mb-2">Your Password</label>
                              <Link onClick={handleTogglePassword}
                                style={{ position: "absolute", right: "20px", zIndex: "9999", bottom: "-36px" }}
                              >
                                <span>
                                  {showPassword ? (
                                    <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                      <path d="M0 0h24v24H0z" fill="none" />
                                      <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                    </svg>
                                  ) : (
                                    <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                        fill="#666666"
                                        fillOpacity="0.8"
                                      />
                                      <path
                                        d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                        fill="#666666"
                                        fillOpacity="0.8"
                                      />
                                    </svg>
                                  )}
                                </span>
                              </Link>
                            </div>
                            <input
                              name="password"
                              {...register("password")}
                              type={showPassword ? "text" : "password"}
                              placeholder=""
                              className="form-control"
                            />
                            <input type="hidden" name="timezone" value={selectedTimezone}
                              {...register("timezone")}
                            />
                            <p className="mt-2 font-bold col-red">
                              <span style={{ color: "red" }}>{errors.password?.message}</span>
                            </p>
                          </div>
                          {/* <div className="text-end mt-2">
                            <Link to="/forgot-password" className="text-decoration-underline text-purple fs-6">
                              Forgot your password?
                            </Link>
                          </div> */}
                          <div className="mt-5 d-flex justify-content-center align-items-center">
                            <button type="submit" className="px-4 py-2 btn-theme bg-yellow text-center fw-600 d-block">
                              Sign in
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
