import { useNavigate } from "react-router-dom";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import Hdashboard from "../../../components/Hdashboard";
import { useEffect, useRef, useState } from "react";
import { add_permission, add_sub_admin, menuList } from "../../../api/apiHandler";
import * as Yup from "yup";
import { TOAST_ERROR, TOAST_SUCCESS, convertToBase64 } from "../../../utils/common.service";
import { useFormik } from "formik";
import { uploadImageOnAWS } from "../../aws/Service";

export default function SubAdminList({isLoader}) {

    const fileRef = useRef();
    const [menu, setMenu] = useState([]);
    const [subAdminId, setSubAdminId] = useState('');
    const [image, setImage] = useState(null);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const adminId = localStorage.getItem("aID");
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const fetchMenuList = () => {
        isLoader(true)
        menuList()
            .then((response) => {
                if (response.code === '1') {
                    setMenu(response.data);
                    isLoader(false)
                } else {
                    console.error("Error fetching menu:", response.message);
                    isLoader(false)
                }
            })
            .catch((error) => {
                console.error("Error fetching menu:", error);
                isLoader(false)
            });
    };

    useEffect(() => {
        fetchMenuList();
    }, []);

    const subAdminSchema = Yup.object({
        name: Yup.string().required("Please enter name").matches(/^[aA-zZ\s]+$/, 'Only characters are allowed in name'),
        email: Yup
            .string()
            .required("Please enter email")
            .email("Please enter valid email address")
            .matches(/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/, 'Please enter valid email address')
            .matches(/^(?!.*@[^,]*,)/, 'Please enter valid email address'),
        password: Yup
            .string()
            .required("Please enter password")
            .matches(/^\S*$/, "Space are not allow in password")
            .matches(
                /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~])(?=.*[0-9]).{8,32}$/,
                "Password must contains 8 or more characters,at least one digit, one special character"
            ),
        profile_image: Yup
            .mixed()
            .required("Please upload image")
        // .matches(/\.(jpg|jpeg|png|webp|avif|gif|bmp)$/i, "Please enter a valid image file")
        // .matches(/^.*\.(jpg|jpeg|png|gif)$/, "Please enter a valid image file"),
        ,
        add_permission: Yup.array()
    });

    const subAdminForm = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            profile_image: null,
            add_permission: []
        },
        validationSchema: subAdminSchema,
        onSubmit: async (values) => {

            if (subAdminForm.values.profile_image != null) {
                var profile_image = await uploadImageOnAWS(subAdminForm.values.profile_image, "admin");
            }

            var obj = {
                admin_id: adminId,
                name: subAdminForm.values.name,
                email: subAdminForm.values.email,
                password: subAdminForm.values.password
            };
            if (subAdminForm.values.profile_image !== null) {
                obj.profile_image = profile_image;
            }
            isLoader(true)
            add_sub_admin(obj).then((response) => {
                const sub_admin_id = response.data;
                setSubAdminId(sub_admin_id);

                if (response.code == 1) {
                    const permissions = selectedPermissions.map(permission => ({
                        admin_id: sub_admin_id,
                        menu_name: permission.menu_name,
                        add: permission.add === true ? 1 : 0,
                        edit: permission.edit === true ? 1 : 0,
                        delete: permission.delete === true ? 1 : 0,
                        view: permission.view === true ? 1 : 0,
                        status: permission.status === true ? 1 : 0
                    }));
                    isLoader(true)
                    add_permission({ permissions }).then((response1) => {
                        if (response1.code == 1) {
                            TOAST_SUCCESS(response1.message);
                            navigate('/sub_admin_list');
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response1.message);
                            isLoader(false)
                        }
                    });
                    isLoader(false)
                } else {
                    TOAST_ERROR(response.message);
                    isLoader(false)
                }
            })
        }
    });

    const uploadImage = () => {
        fileRef.current.click();
    };

    const uploadImageEvent = async (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const bufferImage = await convertToBase64(e.target.files[0]);
            setImage(bufferImage);
            subAdminForm.setFieldValue('profile_image', e.target.files[0]);
        }else{
            setImage(null);
        }
    };

    const displayError = (fieldName) => subAdminForm.touched[fieldName] && subAdminForm.errors[fieldName];

    const handlePermissionClick = (menuItem, index, event) => {
        const isChecked = event.target.checked;
        const updatedPermissions = [...selectedPermissions];

        const selectedPermissionIndex = updatedPermissions.findIndex(permission => permission.menu_name === menuItem.menu_name);
        if (selectedPermissionIndex !== -1) {
            updatedPermissions[selectedPermissionIndex].add = menuItem.add == 1 ? isChecked : 0;
            updatedPermissions[selectedPermissionIndex].edit = menuItem.edit == 1 ? isChecked : 0;
            updatedPermissions[selectedPermissionIndex].delete = menuItem.delete == 1 ? isChecked : 0;
            updatedPermissions[selectedPermissionIndex].view = menuItem.view == 1 ? isChecked : 0;
            updatedPermissions[selectedPermissionIndex].status = menuItem.status == 1 ? isChecked : 0;
        } else {
            const newPermission = {
                menu_name: menuItem.menu_name,
                add: menuItem.add == 1 ? isChecked : 0,
                edit: menuItem.edit == 1 ? isChecked : 0,
                delete: menuItem.delete == 1 ? isChecked : 0,
                view: menuItem.view == 1 ? isChecked : 0,
                status: menuItem.status == 1 ? isChecked : 0
            };
            updatedPermissions.push(newPermission);
        }
        setSelectedPermissions(updatedPermissions);
    };

    const handleChildPermissionClick = (menuName, permissionType) => {
        const updatedPermissions = [...selectedPermissions];
        const permissionIndex = updatedPermissions.findIndex(permission => permission.menu_name === menuName);
        if (permissionIndex !== -1) {
            updatedPermissions[permissionIndex][permissionType] = !updatedPermissions[permissionIndex][permissionType];
            setSelectedPermissions(updatedPermissions);
        }
    };

    return (
        <>
            <JqueryLoad />
            <Hdashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="index.php" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>

                    <div className="class-content">
                        <span className="arrow_left d-block" onClick={handleBack}>
                            <span>
                                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313"></path>
                                </svg>
                                <span className="ms-2">Back</span>
                            </span>
                        </span>

                        <section className="requirement_form">
                            <h2 className="mb-5 fw-600 poppins text-capitalize text-center text-purple">Create Sub Admin</h2>
                            <div className="row align-items-center justify-content-center mb-5">
                                <div className="col-lg-9">
                                    <div className="card border-purple p-4">
                                        <form className="custom_form " id="" name="subAdminForm" onSubmit={subAdminForm.handleSubmit}>

                                            <div className="text-center">
                                                <span onClick={uploadImage}>
                                                    <img
                                                        src={image === null ? "./assets/images/user1.jpg" : image}
                                                        alt=""
                                                        className="img img-responsive"
                                                        style={{ borderRadius: "50%", height: "100px", width: "100px" }}
                                                    />
                                                </span>
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    ref={fileRef}
                                                    className="form-control d-none"
                                                    name="profile_image"
                                                    onChange={uploadImageEvent}
                                                />
                                                <center><label className="fw-600 text-dark">Profile Picture</label></center>
                                                {displayError("profile_image") && (
                                                    <div className="text-danger">{subAdminForm.errors.profile_image}</div>
                                                )}
                                            </div>

                                            <div className="form-group mb-4">
                                                <label className="mb-2 fw-600 text-dark">Name</label>
                                                <input type="text" className="form-control" name="name" value={subAdminForm.values.name} onChange={subAdminForm.handleChange} />
                                                {displayError("name") && (
                                                    <div className="text-danger">{subAdminForm.errors.name}</div>
                                                )}
                                            </div>

                                            <div className="form-group mb-4 ">
                                                <label className="mb-2 text-dark fw-600">Email</label>
                                                <input type="email" className="form-control" name="email" value={subAdminForm.values.email} onChange={subAdminForm.handleChange} />
                                                {displayError("email") && (
                                                    <div className="text-danger">{subAdminForm.errors.email}</div>
                                                )}
                                            </div>

                                            <div className="from-group">
                                                <label className="mb-2 text-dark fw-600">Password</label>
                                                <div
                                                    className="form-group d-flex align-items-center"
                                                >
                                                    <input type={showPassword ? "text" : "password"} className="w-100 form-control" value={subAdminForm.values.password} onChange={subAdminForm.handleChange} name="password" />

                                                    <a href="#" onClick={handleTogglePassword}
                                                        style={{ position: "absolute", right: "35px" }}
                                                    >
                                                        {showPassword ? (
                                                            <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                                                <path d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                                            </svg>
                                                        ) : (
                                                            <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                                                    fill="#666666"
                                                                    fillOpacity="0.8"
                                                                />
                                                                <path
                                                                    d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                                                    fill="#666666"
                                                                    fillOpacity="0.8"
                                                                />
                                                            </svg>
                                                        )}
                                                    </a>

                                                </div>
                                                {displayError("password") && (
                                                    <div className="text-danger">{subAdminForm.errors.password}</div>
                                                )}

                                            </div>

                                            <div className="form-group mb-4 mt-4">
                                                <label className="mb-4 fw-600 text-dark">Select Permission</label><br />

                                                {menu.map((menuItem, index) => (
                                                    <div key={index} className="col-md-12 mb-4">
                                                        <div className="row align-items-center">
                                                            <div className="col-md-2">
                                                                <div className="d-flex align-items-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`permission-${index}`}
                                                                        name={`add_permission`}
                                                                        value={menuItem.menu_name}
                                                                        onChange={(e) => handlePermissionClick(menuItem, index, e)}
                                                                    />
                                                                    <label htmlFor={`permission-${index}`} className="mx-2 fw-500" >
                                                                        {menuItem.menu_name}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            {/* Add */}
                                                            {menuItem.add === 1 ? (
                                                                <div className="col-md-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`add-${index}`}
                                                                        name={`add-${index}`}
                                                                        value="add"
                                                                        checked={selectedPermissions.find(permission => permission.menu_name === menuItem.menu_name)?.add}
                                                                        onChange={() => handleChildPermissionClick(menuItem.menu_name, 'add')}
                                                                    />
                                                                    <label htmlFor={`add-${index}`} className="mx-2">Add</label><br />
                                                                </div>
                                                            ) : <div className="col-md-2">
                                                                <label className="mx-2 text-danger">Add not available</label><br />
                                                            </div>}
                                                            {/* Edit */}
                                                            {menuItem.edit === 1 ? (
                                                                <div className="col-md-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`edit-${index}`}
                                                                        name={`edit-${index}`}
                                                                        value="edit"
                                                                        checked={selectedPermissions.find(permission => permission.menu_name === menuItem.menu_name)?.edit}
                                                                        onChange={() => handleChildPermissionClick(menuItem.menu_name, 'edit')}
                                                                    />
                                                                    <label htmlFor={`edit-${index}`} className="mx-2">Edit</label><br />
                                                                </div>
                                                            ) : <div className="col-md-2">
                                                                <label className="mx-2 text-danger" >Edit not available</label><br />
                                                            </div>}
                                                            {/* Delete */}
                                                            {menuItem.delete === 1 ? (
                                                                <div className="col-md-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`delete-${index}`}
                                                                        name={`delete-${index}`}
                                                                        value="delete"
                                                                        checked={selectedPermissions.find(permission => permission.menu_name === menuItem.menu_name)?.delete}
                                                                        onChange={() => handleChildPermissionClick(menuItem.menu_name, 'delete')}
                                                                    />
                                                                    <label htmlFor={`delete-${index}`} className="mx-2">Delete</label><br />
                                                                </div>
                                                            ) : <div className="col-md-2">
                                                                <label className="mx-2 text-danger" >Delete not available</label><br />
                                                            </div>}
                                                            {/* View */}
                                                            {menuItem.view === 1 ? (
                                                                <div className="col-md-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`view-${index}`}
                                                                        name={`view-${index}`}
                                                                        value="view"
                                                                        checked={selectedPermissions.find(permission => permission.menu_name === menuItem.menu_name)?.view}
                                                                        onChange={() => handleChildPermissionClick(menuItem.menu_name, 'view')}
                                                                    />
                                                                    <label htmlFor={`view-${index}`} className="mx-2">View</label><br />
                                                                </div>
                                                            ) : <div className="col-md-2">
                                                                <label className="mx-2 text-danger" >View not available</label><br />
                                                            </div>}
                                                            {/* Status */}
                                                            {menuItem.status === 1 ? (
                                                                <div className="col-md-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`status-${index}`}
                                                                        name={`status-${index}`}
                                                                        value="status"
                                                                        checked={selectedPermissions.find(permission => permission.menu_name === menuItem.menu_name)?.status}
                                                                        onChange={() => handleChildPermissionClick(menuItem.menu_name, 'status')}
                                                                    />
                                                                    <label htmlFor={`status-${index}`} className="mx-2">Block unblock</label><br />
                                                                </div>
                                                            ) : <div className="col-md-2">
                                                                <label className="mx-2 text-danger" >Blovk unblock not available</label><br />
                                                            </div>}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className="button">
                                                <button type="submit" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </main>
        </>
    );
}

