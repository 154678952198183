import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useRef, useState } from "react";
import { contact_us_reply } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import * as Yup from "yup";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import { useFormik } from "formik";
import { uploadImageOnAWS } from "../../aws/Service";

export default function ContactusReply({isLoader}) {

    const navigate = useNavigate()
    const location = useLocation()
    const PersonId = location.state && location.state.PersonId
    const personEmail = location.state && location.state.Email

    const handleBack = () => {
        navigate(-1);
    };

    const fileInputs = useRef([]);
    const resetFileInputs = () => {
        fileInputs.current.forEach((input) => {
            input.value = '';
        });
    };

    const contactUsSchema = Yup.object({
        subject: Yup.string().required("Please enter Subject"),
        body: Yup.string().required("Please enter body"),
        attachment: Yup.array()
    });

    const contactUsForm = useFormik({
        initialValues: {
            subject: '',
            body: '',
            attachment: ''
        },
        validationSchema: contactUsSchema,

        onSubmit: async (data ,  { resetForm }) => {
            isLoader(true)

            var image = []
            if (data.attachment) {
                for (let i = 0; i < data.attachment.length; i++) {
                    var attachment = await uploadImageOnAWS(data?.attachment[i], "contact_us_reply_attachments");
                    image.push({ attachment: attachment })
                }
                data.attachment = image
            }

            data.email = personEmail
            data.contact_us_id = PersonId

            contact_us_reply(data).then((response) => {
                if (response.code == 1) {
                    isLoader(false)
                    TOAST_SUCCESS(response.message);
                    navigate("/contactus-list")
                      resetForm();
                      resetFileInputs();
                } else {
                    TOAST_ERROR(response.message);
                    isLoader(false)
                }
            });
        },
    });

    const handleImage = async (e) => {
        const selectedImages = Array.from(e.target.files);
        const currentAttachment = contactUsForm.values.attachment || [];
        contactUsForm.setFieldValue('attachment', [...currentAttachment, ...selectedImages]);
    };

    const displayError = (fieldName) =>
        contactUsForm.touched[fieldName] && contactUsForm.errors[fieldName];

    return <>
        <JqueryLoad />
        <Hdashboard />
        <header className="dashboard-toolbar d-lg-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
                <div className="d-flex align-items-center">
                    <a className="menu-toggle align-items-center d-lg-none d-flex">
                        <i className="fa fa-bars" />
                    </a>
                    <a href="index.php" className="d-lg-none d-block brand-logo">
                        <img src="./assets/images/logo.png" alt="logo" />
                    </a>
                </div>

            </div>
        </header>

        <section className="main_sec mt-3 mb-5">
            <div className="mx-5 mt-4">
                <span onClick={handleBack}>
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                    </svg>

                    <span className="ms-2">Back</span>
                </span>
            </div>
            <div className="title">
                <p className="fs-4 fw-500 text-center mb-5 poppins">Send Email</p>
            </div>

            <div className="row g-4 align-items-center justify-content-center">
                <div className="col-lg-8">
                    <div className="card border-purple p-4">
                        <form className="custom_form mt-4" name="contactUsForm" onSubmit={contactUsForm.handleSubmit}>

                            <div className="form-group mb-4">
                                <label htmlFor="subject" className="mb-2">Subject</label>
                                <input type="text" className="form-control" name="subject" value={contactUsForm.values.subject} onChange={contactUsForm.handleChange} />
                                {displayError("subject") && (
                                    <div className="text-danger">{contactUsForm.errors.subject}</div>
                                )}
                            </div>

                            <div className="form-group mb-4">
                                <label className="mb-2" htmlFor="body">Body</label>
                                <textarea type="text" className="form-control" name="body" value={contactUsForm.values.body} onChange={contactUsForm.handleChange} ></textarea>
                                {displayError("body") && (
                                    <div className="text-danger">{contactUsForm.errors.body}</div>
                                )}
                            </div>

                            <div className="form-group mb-4">
                                <label className="mb-2">Upload Attachments</label>
                                <div className="row">
                                    {[0, 1, 2].map((index) => (
                                        <div className="col-lg-4" key={index}>
                                            <div className="form-group mb-lg-0 mb-4 file_upload">
                                                <div className="file_update position-relative">
                                                    <input  
                                                        type="file"
                                                        className="form-control"
                                                        name="attachment"
                                                        ref={(el) => (fileInputs.current[index] = el)}
                                                        onChange={(e) => handleImage(e)}
                                                    />
                                                    <span className="file_icons position-absolute">
                                                        <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="button mt-5 mb-2 d-flex justify-content-center">
                                <button type="submit" className="btn-theme px-4 py-2  text-center text-dif-black fw-500 bg-yellow mb-xl-0 mb-3 d-inline-block">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>
}
