import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { TOAST_ERROR, TOAST_SUCCESS, convertToBase64 } from "../../../utils/common.service";
import { uploadImageOnAWS } from "../../aws/Service";
import { get_category, get_single_subject, get_sub_category, update_subject } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";

export default function EditSubject({ isLoader }) {

    const navigate = useNavigate()
    const fileRef = useRef();
    const [image, setImage] = useState(null);
    const [mainCategory, setMainCategory] = useState([])
    const [subCategory, setSubCategory] = useState([])
    const location = useLocation()
    const subjectId = location.state && location.state.subjectId

    const getSubjectData = () => {
        isLoader(true)
        get_single_subject({ id: subjectId }).then((resposnse) => {
            if (resposnse.code == 1) {
                subjectForm.setValues({
                    subject_name: resposnse.data.subject_name,
                    main_category_id: resposnse.data.main_category_id,
                    category_id: resposnse.data.category_id,
                    image: resposnse.data.subject_category_image
                });
                if (resposnse.data.subject_category_image) {
                    setImage(resposnse.data.subject_category_image);
                }
                isLoader(false)
            }
        });
    }

    const getCategoryData = () => {
        isLoader(true)
        get_category().then((resposnse) => {
            if (resposnse.code == 1) {
                setMainCategory(resposnse.data)
                isLoader(false)
            } else {
                setMainCategory([])
                isLoader(false)
            }
        });
    }

    const getSubCategoryData = (selectedMainCategoryId) => {
        isLoader(true)
        get_sub_category({ main_category_id: selectedMainCategoryId }).then((resposnse) => {
            if (resposnse.code == 1) {
                setSubCategory(resposnse.data)
                isLoader(false)
            } else {
                setSubCategory([])
                isLoader(false)
            }
        });
    }

    const subjectSchema = Yup.object({
        subject_name: Yup.string()
            .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, 'Please enter proper subject name')
            .required("Please enter subject name"),
        main_category_id: Yup.string().required("Please select main category"),
        image: Yup.mixed()
            .test('fileType', 'Only image files are allowed', value => {
                if (!value || !(value instanceof Blob)) return true;
                return (value && /^image\/.+$/i.test(value.type));
            })
            .required("Please upload image"),
        category_id: (subCategory && subCategory.length > 0) ? Yup.string().required("Please select sub category") : Yup.string(),

        category_name: Yup.mixed().test(function (value) {
            const { category_id } = this.parent;

            if (category_id === 'add_new_category' && !value) {
                return this.createError({ message: "Category name is required when adding a new category" });
            }
            return true;
        })
    });

    const subjectForm = useFormik({
        initialValues: {
            subject_name: '',
            main_category_id: '',
            image: null,
            category_id: '',
            category_name: ''
        },
        validationSchema: subjectSchema,
        validateOnBlur: false,
        onSubmit: async (data) => {

            if (data.image != null && data.image instanceof Blob) {
                var subject_category_image = await uploadImageOnAWS(data?.image, "main_category");
            }

            var obj = {
                id: subjectId,
                main_category_id: data?.main_category_id,
                subject_name: data?.subject_name,
                category_id: data?.category_id,
            }
            if (data.image !== null) {
                obj.subject_category_image = subject_category_image
            }
            if (data.category_id === 'add_new_category' && data.category_name !== '') {
                obj.category_name = data.category_name;
            }
            isLoader(true)
            update_subject(obj).then((response) => {
                if (response.code === '1') {
                    TOAST_SUCCESS(response.message);
                    navigate('/subject');
                    isLoader(false)
                } else {
                    TOAST_ERROR(response.message);
                    isLoader(false)
                }
            });
        },
    });

    const uploadImage = () => {
        fileRef.current.click();
    };

    const uploadImageEvent = async (e) => {
        const imgUrl = URL.createObjectURL(e.target.files[0]);
        const bufferImage = await convertToBase64(e.target.files[0]);
        setImage(bufferImage);
        subjectForm.setFieldValue('image', e.target.files[0]);
    };

    useEffect(() => {
        getSubjectData()
        getCategoryData()
    }, [subjectId]);

    useEffect(() => {
        handleMainCategoryChange({ target: { value: subjectForm.values.main_category_id } });
    }, [subjectForm.values.main_category_id]);

    const handleMainCategoryChange = (e) => {
        const selectedMainCategoryId = e.target.value;
        subjectForm.setFieldValue('main_category_id', selectedMainCategoryId);
        setSubCategory([]);
        getSubCategoryData(selectedMainCategoryId);
    };

    const handleBack = () => {
        navigate(-1);
    };

    const displayError = (fieldName) =>
        subjectForm.touched[fieldName] && subjectForm.errors[fieldName];

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>
                <span onClick={handleBack} className="mt-4 mx-5">
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                    </svg>
                    <span className="ms-2">Back</span>
                </span>
                <div className="container">
                    <h2 className="d-flex justify-content-center"> Edit Subject</h2>
                    <div className="row align-items-center justify-content-center mt-5">
                        <div className="col-lg-8">
                            <div className="card border-purple p-4" >
                                <form onSubmit={subjectForm.handleSubmit} className="custom_form mt-4">
                                    <div className="d-flex justify-content-center">
                                        <span onClick={uploadImage}>
                                            <img
                                                src={image === null ? "./image_upload.png" : image}
                                                alt=""
                                                className="img img-responsive card border-purple p-2"
                                                height={"100px"}
                                                width={"100px"}
                                                accept="image/*"
                                                style={{ borderRadius: "50%" }}
                                            />
                                        </span>
                                        <input
                                            type="file"
                                            ref={fileRef}
                                            className="form-control d-none"
                                            name="image"
                                            onChange={uploadImageEvent}
                                        />

                                    </div>
                                    {displayError("image") && (
                                        <div className="text-danger d-flex justify-content-center">{subjectForm.errors.image}</div>
                                    )}
                                    <div className="form-group mb-4">
                                        <label className="mb-2">Category:</label>
                                        <select
                                            onChange={(e) => handleMainCategoryChange(e)}
                                            name="main_category_id"
                                            className="form-select"

                                            value={subjectForm.values.main_category_id}

                                        >
                                            <option value={''}>Select Main Category</option>
                                            {mainCategory?.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.category_name}
                                                </option>
                                            ))}
                                        </select>
                                        {displayError("main_category_id") && (
                                            <div className="text-danger">{subjectForm.errors.main_category_id}</div>
                                        )}
                                    </div>
                                    {/* Sub Category Dropdown */}
                                    <div className="form-group mb-4">
                                        <label className="mb-2">Sub Category:</label>
                                        <select
                                            onChange={subjectForm.handleChange}
                                            name="category_id"
                                            className="form-select"

                                            value={subjectForm.values.category_id}

                                        >
                                            <option value={''}>Select Sub Category</option>
                                            {subCategory && subCategory.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.category_name}
                                                </option>
                                            ))}
                                            <option value={'add_new_category'}>Add New Category</option>

                                        </select>
                                        {displayError("category_id") && (
                                            <div className="text-danger">{subjectForm.errors.category_id}</div>
                                        )}
                                    </div>
                                    {subjectForm.values.category_id === 'add_new_category' && (
                                        <div className="form-group mb-4">
                                            <label className="mb-2">Category Name:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="category_name"
                                                onChange={subjectForm.handleChange}
                                                value={subjectForm.values.category_name}
                                                // style={{ width: "400px" }}
                                            />
                                            {subjectForm.values.category_id === "add_new_category" && displayError("category_name") && (
                                                <div className="text-danger">{subjectForm.errors.category_name}</div>
                                            )}
                                        </div>
                                    )}
                                    <div className="form-group mb-4">
                                        <label className="mb-2">Subject Name:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="subject_name"
                                            onChange={subjectForm.handleChange}
                                            // onBlur={subjectForm.handleBlur}
                                            value={subjectForm.values.subject_name}
                                            
                                        />
                                        {displayError("subject_name") && (
                                            <div className="text-danger">{subjectForm.errors.subject_name}</div>
                                        )}
                                    </div>
                               
                                    <div className="d-flex justify-content-center mt-3">
                                        <button type="submit" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </>
}