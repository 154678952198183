import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { NewsletterList, admin_permission_detail, deleteNewsLetter } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import FontAwesome from "react-fontawesome";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import Swal from "sweetalert2";

import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import customStyles from "../../../utils/dataTableStyles";

export default function SubscriptionUsers({ isLoader }) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const AdminId = localStorage.getItem("aID")
    const [permissions, setPermission] = useState([]);
    const role = localStorage.getItem("adminRole");
    const [search, setSearch] = useState('')

    const NewsLetterData = () => {
        isLoader(true)
        NewsletterList({ search: search }).then((response) => {
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setData(dataWithRowCount);
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        })
    }

    const handleDelete = async (id) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete this user from Newsletter?",
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    isLoader(true)
                    deleteNewsLetter({ id: id }).then((response) => {
                        if (response.code == 1) {
                            setData((prevData) => prevData.filter((user) => user.id !== id))
                            TOAST_SUCCESS(response.message);
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    })
                } catch (error) {
                    TOAST_ERROR("Error deleting category");
                    isLoader(false)
                }
            }
        });
    };

    const subAdminPermission = () => {
        admin_permission_detail({ id: AdminId })
            .then((response) => {
                if (response.code == 1) {
                    setPermission(response.data);
                } else {
                    setPermission([])
                }
            })
            .catch((error) => {
                setPermission([])
            });
    }

    useEffect(() => {
        NewsLetterData()
        if (role !== "S") {
            subAdminPermission();
        }
    }, [search])

    const studentParentPermissions = permissions.find(permission => permission.menu_name === "Newsletter");

    // Check if status,deleting is allowed for "Newsletter"
    const isDeleteAllowed = studentParentPermissions && studentParentPermissions.delete === 1;

    const columns = [
        {
            name: 'No',
            selector: 'count',
            sortable: false,
            width: "200px"
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "400px"
        },
        {
            name: 'Subscribe Date',
            selector: row => {
                const date = new Date(row.created_at);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const formattedMonth = month < 10 ? `0${month}` : month;
                const formattedDay = day < 10 ? `0${day}` : day;
                const year = date.getFullYear();
                return `${formattedDay}-${formattedMonth}-${year}`;
            },
            sortable: true,
            width: "300px"
        },
        {
            name: 'Current Status',
            selector: row => {
                switch (row.is_active) {
                    case 0:
                        return <button className="bg-danger border-0 text-light" style={{width : "100px"}}>Unsubscribed</button>;
                    case 1:
                        return <button className="bg-success border-0 text-light" style={{width : "100px"}}>Subscribed</button>;
                    default:
                        return "";
                }
            },
            sortable: true,
            width: "350px"
        },
        {
            name: 'Actions',
            width: "200px",
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    {(role === "S" || isDeleteAllowed) &&
                        <div className="col me-2">
                            <FontAwesome
                                name="delete"
                                className="fs-5 fa-solid fa-trash  mx-2 my-2 bg-light text-danger"
                                onClick={() => handleDelete(row.id)}
                            ></FontAwesome>
                        </div>
                    }
                </div>
            ),
        }
    ];

    const handleBack = () => {
        navigate(-1);
    };

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>
                <span onClick={handleBack} className="mx-4 mt-3">
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                    </svg>
                    <span className="ms-2">Back</span>
                </span>
                <>
                    <div className="fs-3 fw-500 ms-4 mt-2">
                        Subscription Users For Newsletter
                    </div>
                    <div className="d-flex justify-content-end align-items-center search-container">
                        <div className="row me-5">
                            <div className="col">
                                <CInputGroup>
                                    <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                    <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                        <FontAwesome className="fa-solid fa-search" name="search" />
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>
                    </div>
                    <div className="px-5 mt-4">
                        <div>
                            {(
                                data && data.length > 0 ? (
                                    <DataTable persistTableHead columns={columns} data={data} pagination className="custom-data-table" customStyles={customStyles} />
                                ) : (
                                    <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No Data Found</p>
                                )
                            )}
                        </div>
                    </div>
                </>
            </div>
        </main>
    </>
}

