import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from "formik";
import * as Yup from "yup";
import { TOAST_ERROR, TOAST_SUCCESS } from '../utils/common.service';
import { admin_permission_detail, change_password, logout, update_commission } from '../api/apiHandler';
import { sidebarMenuItems } from './sidebarMenuItems';

export default function Hdashboard() {
  const path = useLocation();
  const AdminId = localStorage.getItem("aID")

  const navigate = useNavigate();

  const changepasswordSchema = Yup.object({
    currentpassword: Yup.string().required("Please Enter Current Password"),
    newpassword: Yup.string().required("Please Enter New Password"),
    confirmpassword: Yup.string()
      .required("Please Enter Confirm Password")
      .oneOf([Yup.ref('newpassword')], 'Passwords Must Match'),
  });

  const changePasswordForm = useFormik({
    initialValues: {
      currentpassword: '',
      newpassword: '',
      confirmpassword: '',
    },
    validationSchema: changepasswordSchema,
    onSubmit: async (values) => {
      var obj = {
        id: AdminId,
        old_password: values.currentpassword,
        new_password: values.newpassword
      }

      change_password(obj).then((response) => {
        if (response.code == 1) {
          TOAST_SUCCESS(response.message);
          changePasswordForm.resetForm();
          const modal = document.getElementById('changepassword');
          modal.classList.remove('show');
          modal.setAttribute('aria-hidden', 'true');
          document.body.classList.remove('modal-open');
          const modalBackdrop = document.querySelector('.modal-backdrop');
          if (modalBackdrop) {
            modalBackdrop.parentNode.removeChild(modalBackdrop);
          }
        } else {
          TOAST_ERROR(response.message);
        }
      });
    },
  });

  const handleLogout = () => {

    logout({ id: AdminId }).then((response) => {
      if (response.code == 1) {
        localStorage.removeItem("AToken");
        localStorage.removeItem("aID");
        localStorage.removeItem("adminRole");
        TOAST_SUCCESS(response.message);
        navigate('/login');
      } else {
        TOAST_ERROR(response.message);
      }
    });
  }

  window.addEventListener('hidden.bs.modal', function (event) {
    if (event.target.id === 'changepassword') {
      changePasswordForm.resetForm();
    }
    else if (event.target.id === 'commission') {
      commissionForm.resetForm();
    }
  });

  const commissionSchema = Yup.object({
    commission: Yup.string().required("Please Enter Commission").matches(/^[0-9]+$/, "Commission Must be a Number"),
  });

  const commissionForm = useFormik({
    initialValues: {
      commission: '',
    },
    validationSchema: commissionSchema,
    onSubmit: async (values) => {
      update_commission({ commission: values.commission }).then((response) => {
        if (response.code == 1) {
          TOAST_SUCCESS(response.message);
          commissionForm.resetForm();
          const modal = document.getElementById('commission');
          modal.classList.remove('show');
          modal.setAttribute('aria-hidden', 'true');
          document.body.classList.remove('modal-open');
          const modalBackdrop = document.querySelector('.modal-backdrop');
          if (modalBackdrop) {
            modalBackdrop.parentNode.removeChild(modalBackdrop);
          }
        } else {
          TOAST_ERROR(response.message);
        }
      });
    },
  });

  const handleCancel = () => {
    changePasswordForm.resetForm();
    commissionForm.resetForm();
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);

    document.querySelectorAll('.dashboard-nav-item').forEach(item => {
      item.parentNode.classList.remove('active');
    });
  };

  const handleLinkClick = (event) => {
    document.querySelectorAll('.dashboard-nav-item.active').forEach(item => {
      item.parentNode.classList.remove('active');
    });
    event.target.parentNode.classList.add('active');
  };


  const displayError = (fieldName) =>
    changePasswordForm.touched[fieldName] && changePasswordForm.errors[fieldName];

  const [permissions, setPermission] = useState([]);
  const role = localStorage.getItem("adminRole");

  const subAdminPermission = () => {
    admin_permission_detail({ id: AdminId })
      .then((response) => {
        if (response.code == 1) {
          setPermission(response.data);
        } else {
          // console.error("Error fetching admin permissions:", response.message);
        }
      })
      .catch((error) => {
        // console.error("Error fetching admin permissions:", error);
      });
  }

  // useEffect(() => {
  //   subAdminPermission();
  // }, [])

  useEffect(() => {
    if (role !== "S") {
      subAdminPermission();
    }
  }, [])


  const isAllowed = (menuItem) => {
    if (role === "S") {
      return true;
    }
    const permission = permissions.find(item => item.menu_name === menuItem);
    return permission && permission.view === 1;
  };

  const isActive = (itemPath, activePaths) => {
    if (activePaths && activePaths.some(activePath => path.pathname.startsWith(activePath))) {
      return "active";
    }
    return "";
  };

  return (
    <>
      {localStorage.getItem("AToken") && (
        <div>
          <div className="loader-wrap" style={{ display: 'none' }}>
            {/* <img src="./assets/images/mortarboard.png" alt="Scholarship Hat" id="hat-image" />	 */}
          </div>
          <div className="dashboard">
            <div className="dashboard-nav" id="style-3">
              <header>
                <Link className="menu-toggle">
                  <i className="fa fa-bars" />
                </Link>
                <Link to="/dashboard" className="brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </Link>
              </header>
              <nav className="dashboard-nav-list">
                <ul>

                  {sidebarMenuItems.map((item, index) => {
                    if (isAllowed(item.label)) {
                      return (
                        <li key={index} className={isActive(item.path, item.activePaths)}>
                          <Link to={item.path} className="dashboard-nav-item">
                            <span>
                              {item.icon}
                            </span>
                            <span className="fs-6 ms-0 w-auto">{item.label}</span>
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}

                  {localStorage.getItem("AToken") && (
                    <li className={isDropdownOpen ? 'active' : ''}>
                      <Link className="dashboard-nav-item" onClick={handleDropdownToggle}>
                        <span>
                          <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#570861" d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" /></svg>
                        </span>
                        <span className="fs-6 ms-0 w-auto">Settings</span>

                      </Link>
                    </li>
                  )}


                  {isDropdownOpen && (
                    <div className="dropdown">
                      <span className="dropdown-item dashboard-nav-item" data-bs-toggle="modal" data-bs-target="#changepassword" onClick={handleLinkClick}>
                        Change Password
                      </span>
                      {/* {
                        role == "S" ? <span className="dropdown-item dashboard-nav-item" data-bs-toggle="modal" data-bs-target="#commission" onClick={handleLinkClick}>
                          Commission
                        </span> : ""
                      } */}
                    </div>
                  )}

                  {localStorage.getItem("AToken") && (
                    <li>
                      <Link className="dashboard-nav-item" data-bs-toggle="modal" data-bs-target="#logout">
                        <span>
                          <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.4989 10.8334C12.0381 10.8334 11.6656 11.2068 11.6656 11.6667V15.0001C11.6656 15.4592 11.2922 15.8334 10.8322 15.8334H8.33222V3.3335C8.33222 2.62183 7.87891 1.98601 7.19727 1.74934L6.95056 1.6668H10.8322C11.2922 1.6668 11.6656 2.04094 11.6656 2.50023V5.0002C11.6656 5.46008 12.0381 5.83348 12.4989 5.83348C12.9597 5.83348 13.3322 5.46008 13.3322 5.0002V2.50023C13.3322 1.12192 12.2105 0.000252685 10.8322 0.000252685H1.87401C1.84229 0.000252685 1.81572 0.0144322 1.7849 0.0185728C1.74475 0.0152135 1.70647 0.000213623 1.66573 0.000213623C0.746555 0.000252685 -0.000976562 0.747667 -0.000976562 1.66684V16.6667C-0.000976562 17.3784 0.452339 18.0142 1.13397 18.2509L6.14896 19.9226C6.31896 19.975 6.48802 20.0001 6.66564 20.0001C7.58481 20.0001 8.33218 19.2525 8.33218 18.3334V17.5001H10.8322C12.2105 17.5001 13.3321 16.3784 13.3321 15.0001V11.6667C13.3321 11.2068 12.9597 10.8334 12.4989 10.8334H12.4989Z" fill="#570861" />
                            <path d="M19.7545 7.74431L16.4211 4.41106C16.3046 4.29444 16.1561 4.215 15.9945 4.18278C15.8328 4.15057 15.6652 4.16703 15.5129 4.23009C15.2021 4.35935 14.9987 4.66345 14.9987 5.0002V7.50017H11.6655C11.2054 7.50017 10.832 7.87341 10.832 8.33345C10.832 8.79348 11.2054 9.16672 11.6655 9.16672H14.9987V11.6667C14.9987 12.0035 15.2021 12.3075 15.5129 12.4368C15.6652 12.4999 15.8328 12.5163 15.9945 12.4841C16.1561 12.4519 16.3046 12.3725 16.4211 12.2559L19.7545 8.92258C20.0803 8.59681 20.0803 8.07009 19.7545 7.74431Z" fill="#570861" />
                          </svg>
                        </span>
                        <span className="fs-6 ms-0 w-auto">Logout </span>
                      </Link>
                    </li>
                  )}

                </ul>
              </nav>
            </div>
          </div>
          <div className="modal fade" id="changepassword" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-top">
              <div className="modal-content">
                <div className="modal-header pb-0 border-bottom-0">
                  <h5 className="modal-title" id="exampleModalLabel" />
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancel} />
                </div>
                <div className="modal-body">
                  <h3 className='text-center mb-3'>Change Password</h3>
                  <form onSubmit={changePasswordForm.handleSubmit} className="custom_form mt-4">
                    <div className="form-group mb-4">
                      <label className="mb-2">Current Password:</label>
                      <input
                        type="password"
                        className="form-control"
                        name="currentpassword"
                        onChange={changePasswordForm.handleChange}
                        value={changePasswordForm.values.currentpassword}
                      />
                      {displayError("currentpassword") && (
                        <div className="text-danger">{changePasswordForm.errors.currentpassword}</div>
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">New Password:</label>
                      <input
                        type="password"
                        className="form-control"
                        name="newpassword"
                        onChange={changePasswordForm.handleChange}
                        value={changePasswordForm.values.newpassword}
                      />
                      {displayError("newpassword") && (
                        <div className="text-danger">{changePasswordForm.errors.newpassword}</div>
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Confirm New Password:</label>
                      <input
                        type="password"
                        className="form-control"
                        name="confirmpassword"
                        onChange={changePasswordForm.handleChange}
                        value={changePasswordForm.values.confirmpassword}
                      />
                      {displayError("confirmpassword") && (
                        <div className="text-danger">{changePasswordForm.errors.confirmpassword}</div>
                      )}
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 pt-0">
                      <Link className="btn-theme bg-yellow fw-500" data-bs-dismiss="modal" onClick={handleCancel}>Cancel</Link>
                      <button type="submit" className="btn-theme fw-500">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="commission" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-top">
              <div className="modal-content">
                <div className="modal-header pb-0 border-bottom-0">
                  <h5 className="modal-title" id="exampleModalLabel" />
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancel} />
                </div>
                <div className="modal-body">
                  <h3 className='text-center mb-3'>Add Commission</h3>
                  <form onSubmit={commissionForm.handleSubmit} className="custom_form mt-4">
                    <div className="form-group mb-4">
                      <label className="mb-2">Commission:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="commission"
                        onChange={commissionForm.handleChange}
                        value={commissionForm.values.commission}
                      />
                      <div className="text-danger">{commissionForm.errors.commission}</div>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 pt-0">
                      <Link className="btn-theme bg-yellow fw-500" data-bs-dismiss="modal" onClick={handleCancel}>Cancel</Link>
                      <button type="submit" className="btn-theme fw-500">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal */}
      <div className="modal fade" id="logout" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header pb-0 border-bottom-0">
              <h5 className="modal-title" id="exampleModalLabel" />
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <h2 className="fs-5 text-center poppins fw-600 text-purple">Are you sure you want to log out?</h2>
            </div>
            <div className="modal-footer justify-content-center border-top-0 pt-0">
              <Link className="btn-theme bg-yellow fw-500" data-bs-dismiss="modal">No</Link>
              <button onClick={() => handleLogout()} data-bs-dismiss="modal" className="btn-theme fw-500">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
