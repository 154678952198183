import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useRef, useState } from "react";
import { sendComment, teacherBackground, teacherStepAccept } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import Swal from "sweetalert2";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import * as Yup from "yup";
import { useFormik } from "formik";

export default function OrgTeacherBg({isLoader}) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const teacherID = location.state && location.state.teacherId
    const email = location.state && location.state.Email

    const GetTBackgroundData = () => {
        isLoader(true)
        teacherBackground({ teacher_id: teacherID }).then((response) => {
            if (response.code == 1) {
                setData(response.data[0])
                isLoader(false)
            }else{
                setData([])
                isLoader(false)
            }
        })
    }

    useEffect(() => {
        GetTBackgroundData();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handleAccpet = () => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to accept teacher background details?",
            showCancelButton: true,
            confirmButtonText: "Accept",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    var objAccept = {
                        teacher_id: teacherID,
                        email : email,
                        accept: "2",
                        step: "1",
                        accept_for: "2"
                    };
                    isLoader(true)
                    teacherStepAccept(objAccept).then((response) => {
                        if (response.code == 1) {
                            TOAST_SUCCESS(response.message);
                            setData(prevData => ({ ...prevData, step_1: "2" }));
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    });
                } catch (error) {
                    TOAST_ERROR("Error..!!");
                    isLoader(false)
                }
            }
        });
    }

    const handleReject = () => {

        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to reject teacher background details?",
            showCancelButton: true,
            confirmButtonText: "Reject",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    var objReject = {
                        teacher_id: teacherID,
                        email :  email,
                        accept: "3",
                        step: "1",
                        accept_for: "2"
                    };
                    isLoader(true)
                    teacherStepAccept(objReject).then((response) => {
                        if (response.code == 1) {
                            TOAST_SUCCESS(response.message);
                            setData(prevData => ({ ...prevData, step_1: "3" }));
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    });
                } catch (error) {
                    TOAST_ERROR("Error..!!");
                    isLoader(false)
                }
            }
        });
    }

    const fileInputs = useRef([]);
    const resetFileInputs = () => {
        fileInputs.current.forEach((input) => {
            input.value = '';
        });
    };

    const commentSchema = Yup.object({
        comment: Yup.string()
    });

    const commentForm = useFormik({
        initialValues: {
            comment: '',
        },
        validationSchema: commentSchema,

        onSubmit: async (data) => {

            data.id = teacherID;
            data.email = email;
            data.comment_for = 3; //comment_for 1 = teacher , 2 = organization , 3 = organization_teacher
            data.step = 1;
            isLoader(true)
            sendComment(data).then((response) => {
                if (response.code == 1) {
                    TOAST_SUCCESS(response.message);
                    commentForm.resetForm();
                    resetFileInputs();
                    GetTBackgroundData();
                    isLoader(false)
                } else {
                    TOAST_ERROR(response.message);
                    isLoader(false)
                }
            });
        },
    });

    const displayError = (fieldName) =>
        commentForm.touched[fieldName] && commentForm.errors[fieldName];

    return <>
        <JqueryLoad />
        <Hdashboard />
        <header className="dashboard-toolbar d-lg-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
                <div className="d-flex align-items-center">
                    <a className="menu-toggle align-items-center d-lg-none d-flex">
                        <i className="fa fa-bars" />
                    </a>
                    <a href="index.php" className="d-lg-none d-block brand-logo">
                        <img src="./assets/images/logo.png" alt="logo" />
                    </a>
                </div>
            </div>
        </header>
        <section className="main_sec">
            <div className="mt-4 mx-5 mb-5">
                <div>
                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>
                </div>
                {(Object.keys(data).length > 0 ? (
                        <>
                            <p className="ms-3 fs-3 fw-500 text-center mb-4">Organization Teacher Background Details</p>
                            <div className="row d-flex justify-content-center align-items-center">
                                <div className="col col-lg-10 mb-4 mb-lg-0">
                                    <div className="card mb-3 p-3" style={{ borderRadius: ".5rem" }}>
                                        <div className="row g-0">
                                            <div className="col-md-12">
                                                <div className="card-body p-4">
                                                    <h6 className="mb-2">Information</h6>
                                                    <hr className="mt-0 mb-4" />
                                                    <div className="row pt-1">
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-600">Are You Going To Teach Children, Under 18 Years Old?</p>
                                                            <p className="text-muted mt-1">{data.teach_children}</p>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-600">Are You Currently A Certified School Board Teacher?</p>
                                                            <p className="text-muted mt-1">{data.certified_school_board}</p>
                                                        </div>
                                                        {data.certified_school_board == "yes" &&

                                                            <div className="col-12 mb-4">
                                                                <p className="fw-600">Teaching Certificate</p>
                                                                {data.teacher_certificate && (
                                                                    <a href={data.teacher_certificate
                                                                    } className="text-purple underline mt-1">View Certificate</a>
                                                                )}
                                                            </div>
                                                        }
                                                        {data.certified_school_board == "no" &&
                                                            <div className="col-12 mb-4">
                                                                <p className="fw-600">Teacher registration number</p>
                                                                <p className="text-muted mt-1">{data.registration_number}</p>
                                                            </div>
                                                        }

                                                        <div className="col-12 mb-4">
                                                            <p className="fw-600">Are You Currently Teaching With A School Board Or At A Private School?</p>
                                                            <p className="text-muted mt-1">{data.private_school}</p>
                                                        </div>
                                                        {data.private_school == "yes" &&
                                                            <>
                                                                <div className="col-12 mb-4">
                                                                    <p className="fw-600">School Name</p>
                                                                    <p className="text-muted mt-1">{data.school_name}</p>
                                                                </div>
                                                                <div className="col-12 mb-4">
                                                                    <p className="fw-600">Full Address Of The School</p>
                                                                    <p className="text-muted mt-1">{data.school_address}</p>
                                                                </div>

                                                                <div className="col-12 mb-4">
                                                                    <p className="fw-600">School Contact Phone Number</p>
                                                                    <p className="text-muted mt-1">{data.school_contact}</p>
                                                                </div>

                                                                <div className="col-12 mb-4">
                                                                    <p className="fw-600">School Contact Email Address</p>
                                                                    <p className="text-muted mt-1">{data.school_email}</p>
                                                                </div>

                                                                <div className="col-12 mb-4">
                                                                    <p className="fw-600">Background Check To Teach Children Under 18 Years As Per Your Country Requirements.</p>
                                                                    {data.teacher_back_info && (
                                                                        <a href={data.teacher_back_info} className="text-purple underline mt-1">View Document</a>
                                                                    )}
                                                                </div>

                                                                <div className="col-12 mb-4">
                                                                    <p className="fw-600">Expiry Date Of The Background Check - Month And Year</p>
                                                                    <p className="text-muted mt-1">{data.expiry_date}</p>
                                                                </div>
                                                            </>
                                                        }

                                                        {data.private_school == "no" &&
                                                            <div className="col-12 mb-4">
                                                                <p className="fw-600">To Legally Teach Children, You Are Required To Have A Safety Check Done As Per Your Country Requirement</p>
                                                                <p className="text-muted mt-1">{data.legal}</p>
                                                            </div>
                                                        }
                                                        {data.legal == "yes" &&
                                                            <div className="col-12 mb-4">
                                                                <p className="fw-600">Do You Have A Valid Legal Check ? </p>
                                                                {data.teacher_valid_check && (
                                                                    <a href={data.teacher_valid_check} className="text-purple underline mt-1">View Document</a>
                                                                )}
                                                            </div>
                                                        }
                                                        {data.legal == "no" &&
                                                            <div className="col-12 mb-4">
                                                                <p className="fw-600">Teacher Doesn't Have A Valid Legal Check.</p>
                                                                <p className="text-muted mt-1">{data.local_police_station}</p>
                                                            </div>
                                                        }
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-600">The Following Requirements Are Necessary To Teach Children Below 18 Years Of Age. Please Upload This Document As Per The Country You Live In: </p>
                                                            <p className="text-muted mt-1">{data.country_livein}</p>
                                                        </div>

                                                        {data.country_livein == "Australia" &&
                                                            <div className="col-12 mb-4">
                                                                <p className="fw-600">If You Reside In Australia - Work With Children Check</p>
                                                                {data.teacher_reside && (
                                                                    <a href={data.teacher_reside} className="text-purple underline mt-1">View Document</a>
                                                                )}
                                                            </div>
                                                        }
                                                        {data.country_livein == "Canada" &&
                                                            <div className="col-12 mb-4">
                                                                <p className="fw-600">If You Reside In Canada - Vulnerable Sector Check
                                                                </p>
                                                                {data.teacher_reside && (
                                                                    <a href={data.teacher_reside} className="text-purple underline mt-1">View Document</a>
                                                                )}
                                                            </div>
                                                        }
                                                        {data.country_livein == "India" &&
                                                            <div className="col-12 mb-4">
                                                                <p className="fw-600">If You Reside In India - National Criminal Background Check</p>
                                                                {data.teacher_reside && (
                                                                    <a href={data.teacher_reside} className="text-purple underline mt-1">View Document</a>
                                                                )}
                                                            </div>
                                                        }
                                                        {data.country_livein == "New Zealand" &&
                                                            <div className="col-12 mb-4">
                                                                <p className="fw-600">If You Reside In New Zeland - National Criminal Background Check</p>
                                                                {data.teacher_reside && (
                                                                    <a href={data.teacher_reside} className="text-purple underline mt-1">View Document</a>
                                                                )}
                                                            </div>
                                                        }
                                                        {data.country_livein == "Singapore" &&
                                                            <div className="col-12 mb-4">
                                                                <p className="fw-600">If You Reside In Singapore - Complete Criminal Background Check </p>
                                                                {data.teacher_reside && (
                                                                    <a href={data.teacher_reside} className="text-purple underline mt-1">View Document</a>
                                                                )}
                                                            </div>
                                                        }
                                                        {data.country_livein == "UK" &&
                                                            <div className="col-12 mb-4">
                                                                <p className="fw-600">If You Reside In UK - Enhanced Database Check</p>
                                                                {data.teacher_reside && (
                                                                    <a href={data.teacher_reside} className="text-purple underline mt-1">View Document</a>
                                                                )}
                                                            </div>
                                                        }
                                                        {data.country_livein == "USA" &&
                                                            <div className="col-12 mb-4">
                                                                <p className="fw-600">If You Reside In The USA - Two Checks Are Required. County-Wide Criminal Background Check And Sex Offender Registry Search
                                                                </p>
                                                                {data.teacher_reside && (
                                                                    <a href={data.teacher_reside} className="text-purple underline mt-1">View Document</a>
                                                                )}
                                                            </div>
                                                        }

                                                        <div className="col-12 mb-4">
                                                            <p className="fw-600">Have You Been Convicted For Violence, Sexual Abuse, Child Abuse, Or Neglect?</p>
                                                            <p className="text-muted mt-1">{data.convicted}</p>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-600">Do You Have Offences Relating To Possession Or Supply Of Illegal Drugs?</p>
                                                            <p className="text-muted mt-1">{data.offences}</p>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-600">Do You Have Any Outstanding Crimes That May Affect The Safety Of Our Learners?
                                                            </p>
                                                            <p className="text-muted mt-1">{data.outstanding}</p>
                                                        </div>

                                                        <div className="col-12 mb-4">
                                                            <p className="fw-600">Have You Been Convicted Of Fraud And Deception, Internet Crimes, Statutory Crimes Or Criminal Intent?</p>
                                                            <p className="text-muted mt-1">{data.convicted_fraud}</p>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-600">Have You Been Barred From Teaching Children And Adults In Any Country?</p>
                                                            <p className="text-muted mt-1">{data.barred}</p>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-600"> Additional Information
                                                            </p>
                                                            <p className="text-muted mt-1">{data.additional_info}</p>
                                                        </div>

                                                        <div className="col-12 mb-4">
                                                            <p className="fw-500">Admin Comments</p>
                                                            <p className="text-muted mt-1">{data.org_teacher_comment}</p>
                                                        </div>
                                                    </div>

                                                    <form className="custom_form mt-4" name="commentForm" onSubmit={commentForm.handleSubmit}>

                                                        <div className="form-group mb-4">
                                                            <p className="fw-500 mb-2">Any comments you have, please add them here for the teacher.</p>
                                                            <textarea type="text" className="form-control" name="comment" value={commentForm.values.comment} rows="5" cols="33" onChange={commentForm.handleChange} ></textarea>
                                                            {displayError("comment") && (
                                                                <div className="text-danger">{commentForm.errors.comment}</div>
                                                            )}
                                                            <div className="button mt-2 mb-2 d-flex justify-content-end">
                                                                <button type="submit" className="btn-theme px-4 py-2  text-center text-dif-black fw-500 bg-yellow mb-xl-0 mb-3 d-inline-block">Send</button>
                                                            </div>
                                                        </div>
                                                    </form>

                                                    <div className="d-flex justify-content-center mt-3">
                                                        <button className="btn-theme bg-yellow mx-1" onClick={() => handleAccpet()}>
                                                            {data.step_1 == "2" ? "Accpted" : "Accept"}
                                                        </button>
                                                        <button className="btn-theme mx-1" onClick={() => handleReject()}>
                                                            {data.step_1 == "3" ? "Rejected" : "Reject"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div>
                            <p className="text-center fs-5 fw-500 text-danger mt-5">The Organization Teacher has not filled out the Background Details Form.</p>
                        </div>
                    )
                )}
            </div>
        </section>
    </>
}

